import "./style.scss";

import React, { Fragment } from "react";

import { ImgOmnia } from "../ImgOmnia";

const PlaceholderCcp: React.FC<unknown> = () => {
  return (
    <Fragment>
      <div className="ccp-placeholder-image">
        <ImgOmnia name="connect-popped-out" />
      </div>
      <div className="ccp-placeholder-text">
        Amazon Connect is open in another window
      </div>
    </Fragment>
  );
};

export default PlaceholderCcp;
