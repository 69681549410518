import React, { useContext, useEffect, useRef, useState } from "react";

import { logger } from "../../logger";
import { AppStateContext } from "../../state/provider";
import { CfmWidget } from "../CfmWidget";
import { getTransferFeedbackWidgetConfig } from "../CfmWidget/config";
import { CHAT_ESCALATION_QUEUE, enableTransferCfmWidget } from "../config";
import { useMounted } from "../hooks/common";

export const TransferSurvey: React.FC<unknown> = () => {
  const refCurrentViewedContactId = useRef<string>(); // This is used to keep track of the current contact on the screen.
  const [displaySurvey, setDisplaySurvey] = useState<boolean>(false);
  const mounted = useMounted();
  const appState = useContext(AppStateContext);

  useEffect(() => {
    if (appState.ccpInitialized) {
      connect.agent((agent) => addConnectEventListeners(agent));
    }
  }, [appState.ccpInitialized]);

  /**
   * This function returns the contact object of the specified contact id corresponding to an agent
   * @param agent the agent object.
   * @param contactId the contactId in Amazon Connect.
   * @returns
   */
  const getChatContact = (
    agent: connect.Agent,
    contactId: string
  ): connect.Contact | undefined => {
    const contact = agent
      .getContacts(connect.ContactType.CHAT)
      .find((contact) => contact.contactId === contactId);
    return contact;
  };

  /**
   * This function returns true if the specified transfer chat contact has been transferred
   * @param contact the Amazon Connect contact object
   * @returns
   */
  const isTransferContact = (contact: connect.Contact): boolean => {
    return (
      contact.getOriginalContactId() !== null &&
      contact.getOriginalContactId() !== "" &&
      contact.getOriginalContactId() !== contact.getContactId()
    );
  };

  /**
   * This function returns true if the specified transfer is an escalation
   * @param contact the Amazon Connect contact object
   * @returns
   */
  const isEscalation = (contact: connect.Contact): boolean => {
    return contact.getQueue().name.toLowerCase() === CHAT_ESCALATION_QUEUE;
  };

  /**
   * This function returns true if the specified chat contact has ended
   * @param contact the Amazon Connect contact object
   * @returns
   */
  const isEndedChatContact = (contact: connect.Contact): boolean => {
    return (
      contact.getState().type === connect.ContactStateType.ENDED &&
      contact.getType() === connect.ContactType.CHAT
    );
  };

  const addConnectEventListeners = (agent: connect.Agent): void => {
    // get contact id of current contact in view
    // subscribe to changes in the viewed contact and
    // display the survey panel if the contact in view is a transferred connect contact and has ended
    connect.core.onViewContact((viewedContact) => {
      if (mounted.current) {
        refCurrentViewedContactId.current = viewedContact.contactId;
        const contact = getChatContact(agent, viewedContact.contactId);
        if (contact) {
          setDisplaySurvey(
            isEscalation(contact) &&
              isTransferContact(contact) &&
              isEndedChatContact(contact)
          );
        } else {
          // This is not always an error, e.g if the viewed contact is for a different channel than chat
          logger.info(
            `Contact: ${viewedContact.contactId} was not found amongst the agent list`
          );
          setDisplaySurvey(false);
        }
      }
    });
    connect.contact((contact) => {
      // display the survey panel for contact in view that has ended
      contact.onEnded((contact) => {
        if (mounted.current) {
          if (refCurrentViewedContactId.current === contact.contactId) {
            setDisplaySurvey(
              isEscalation(contact) &&
                isTransferContact(contact) &&
                isEndedChatContact(contact)
            );
          }
        }
      });
    });
  };

  return displaySurvey ? (
    enableTransferCfmWidget() ? (
      <div>
        <CfmWidget
          contactID={refCurrentViewedContactId.current}
          configFunc={getTransferFeedbackWidgetConfig}
        ></CfmWidget>
      </div>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};
