export const ITS_MESSAGE_EXTERNAL_SESSION_STRINGS: Record<string, string> = {
  eng:
    "Please use this link to download the remote software and start a session. \n\n This tool allows you to share your screen and give the engineering support team access to help you.",
  spa:
    "Utilice este enlace para descargar el software remoto e iniciar una sesión. \n\n Esta herramienta le permite compartir su pantalla y dar acceso al equipo de soporte de ingeniería para que lo ayude.",
  deu:
    "Bitte benutzen Sie diesen Link, um die Remote-Software herunterzuladen und eine Sitzung zu starten. \n\n Mit diesem Tool können Sie Ihren Bildschirm teilen und dem technischen Support-Team Zugriff gewähren, um Ihnen zu helfen.",
  por:
    "Use este link para baixar o software remoto e iniciar uma sessão. \n\n Essa ferramenta permite que você compartilhe sua tela e dê acesso à equipe de suporte de engenharia para ajudá-lo.",
  fra:
    "Cliquez sur ce lien pour télécharger le logiciel à distance et démarrer une session. \n\n Cet outil vous permet de partager votre écran et de donner à l'équipe d'assistance technique l'accès pour vous aider.",
  ita:
    "Utilizza questo link per scaricare il software remoto e avviare una sessione. \n\n Questo strumento ti consente di condividere lo schermo e fornire al team di supporto tecnico l'accesso per aiutarti.",
  jpn:
    "このリンクを使用してリモートソフトウェアをダウンロードし、セッションを開始してください。\n\n このツールを使用すると、画面を共有し、エンジニアリングサポートチームにアクセスして支援してもらうことができます。",
  kor:
    "이 링크를 사용하여 원격 소프트웨어를 다운로드하고 세션을 시작하십시오. \n\n 이 도구를 사용하면 화면을 공유하고 엔지니어링 지원 팀에 도움을 줄 수 있습니다.",
  zho:
    "请使用此链接下载远程软件并启动会话。\n\n 此工具允许您共享屏幕，并允许工程支持团队访问以帮助您。",
};
