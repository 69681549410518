import React from "react";

import { UiInitOptions } from "../../..";
import { AppStateProvider } from "../../state/provider";
import { OnCcpInitContext } from "../contexts";
import { MainContainer } from "./Container";
import { MainLoader } from "./Loader";

export type MainProps = Pick<
  UiInitOptions,
  "onCcpInit" | "onCloseClick" | "connectInstanceCcpUrl"
>;

export const Main: React.FC<MainProps> = ({
  onCcpInit,
  onCloseClick,
  connectInstanceCcpUrl,
}) => {
  return (
    <AppStateProvider>
      <OnCcpInitContext.Provider value={onCcpInit}>
        <MainLoader>
          <MainContainer
            onCloseClick={onCloseClick}
            connectInstanceCcpUrl={connectInstanceCcpUrl}
          />
        </MainLoader>
      </OnCcpInitContext.Provider>
    </AppStateProvider>
  );
};
