import React from "react";

import { IconBase } from "../types";

export const IconPopIn: React.FC<IconBase> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g transform="translate(11.500000, 11.500000) scale(-1, -1) translate(-11.500000, -11.500000) translate(4.000000, 3.500000)">
              <g>
                <path
                  d="M14.706,0.644583333 L14.706,0.515666667 L14.67,0.442 L14.634,0.38675 L14.544,0.27625 L14.544,0.27625 L14.4,0.16575 L14.346,0.128916667 L14.274,0.0920833333 L10.098,0.0920833333 C9.84076877,0.0920833333 9.60307731,0.2324912 9.4744617,0.460416662 C9.34584608,0.688342124 9.34584608,0.969157876 9.4744617,1.19708334 C9.60307731,1.4250088 9.84076877,1.56541667 10.098,1.56541667 L12.258,1.56541667 L5.796,8.12175 C5.61272274,8.30926977 5.54114482,8.58258581 5.60822895,8.8387426 C5.67531307,9.09489938 5.87086757,9.29498061 6.12122896,9.36361761 C6.37159034,9.43225461 6.63872275,9.35901979 6.822,9.1715 L13.284,2.55991667 L13.284,4.76991667 C13.284,5.17676643 13.606355,5.50658333 14.004,5.50658333 C14.401645,5.50658333 14.724,5.17676643 14.724,4.76991667 L14.724,0.7735 L14.724,0.7735 C14.7217314,0.730068367 14.7157078,0.686927626 14.706,0.644583333 Z"
                  id="Path"
                ></path>
                <path
                  d="M2.196,15.0648333 L10.728,15.0648333 C11.9209351,15.0648333 12.888,14.0753826 12.888,12.8548333 L12.888,7.64291667 C12.888,7.2360669 12.565645,6.90625 12.168,6.90625 C11.770355,6.90625 11.448,7.2360669 11.448,7.64291667 L11.448,12.8548333 C11.448,13.2616831 11.125645,13.5915 10.728,13.5915 L2.196,13.5915 C1.79835498,13.5915 1.476,13.2616831 1.476,12.8548333 L1.476,4.12533333 C1.476,3.71848357 1.79835498,3.38866667 2.196,3.38866667 L7.29,3.38866667 C7.68764502,3.38866667 8.01,3.05884977 8.01,2.652 C8.01,2.24515023 7.68764502,1.91533333 7.29,1.91533333 L2.196,1.91533333 C1.00306494,1.91533333 0.036,2.90478404 0.036,4.12533333 L0.036,12.8548333 C0.036,14.0753826 1.00306494,15.0648333 2.196,15.0648333 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
