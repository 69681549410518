import { default as Select } from "@amzn/awsui-components-react/polaris/select";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import { AmazonConnectInstance } from "../../profiles-reader";
import { setSelectedInstance as setSelectedInstanceAction } from "../../state/actions";
import { useDispatch, useSelector } from "../../state/hooks";

/**
 * Creates a copy of shared instances and manages it's state locally as Select.Option[]
 */
export function useInstanceSelector(): {
  selectedInstanceOption?: Select.Option;
  setSelectedInstanceOption: Dispatch<
    SetStateAction<Select.Option | undefined>
  >;
  options: Select.Option[];
  selectedInstance?: AmazonConnectInstance;
  setSelectedInstance: (instance?: AmazonConnectInstance) => void;
  instances: AmazonConnectInstance[];
} {
  const dispatch = useDispatch();
  const instances = useSelector((state) => state.instances);
  const selectedInstance = useSelector((state) => state.selectedInstance);
  const [selectedInstanceOption, setSelectedInstanceOption] = useState<
    Select.Option | undefined
  >(
    selectedInstance
      ? { id: selectedInstance.ccpUrl, label: selectedInstance.name }
      : undefined
  );

  const setSelectedInstance = (instance?: AmazonConnectInstance): void => {
    dispatch(setSelectedInstanceAction(instance));
  };

  // Update the selected option as selected instance changes
  // This only occurs when two components that modify selectedInstance are mounted at once
  useEffect(() => {
    setSelectedInstanceOption(
      selectedInstance
        ? { id: selectedInstance.ccpUrl, label: selectedInstance.name }
        : undefined
    );
  }, [selectedInstance]);

  // options (mapped from instances)
  const options = useMemo(() => {
    return instances.map((x) => {
      const option = { id: x.ccpUrl, label: x.name };
      return option;
    });
    // eslint-disable-next-line @typescript-eslint/unbound-method
  }, [instances]);

  return {
    selectedInstanceOption,
    setSelectedInstanceOption,
    options,
    selectedInstance,
    setSelectedInstance,
    instances,
  };
}
