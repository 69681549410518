import { CognitoOidcSsoLoginCredentials } from "@amzn/cognito-oidc-sso-login-client";

import { Stage } from "../../configuration";
import { getStage } from "../config";

/** The authentication base URL to get AWS creds. */
export function getCognitoOidcBaseUrl(stage?: Stage): string {
  switch (stage || getStage()) {
    case "dev":
    case "beta":
      return "https://beta.omnia.it.a2z.com";
    case "gamma":
      return "https://gamma.omnia.it.a2z.com";
    case "prod":
      return "https://omnia.it.a2z.com";
  }
}

/** The dialog base URL. */
export function getDialogReturnUrl(stage?: Stage): URL {
  return new URL(`${getCognitoOidcBaseUrl(stage)}/cognito-oidc-sso-login.html`);
}

const CREDENTIALS = new CognitoOidcSsoLoginCredentials(
  new URL(getCognitoOidcBaseUrl()),
  getDialogReturnUrl()
);

/** Does a fetch to webappURL/sso/login to get AWS creds */
export async function auth(): Promise<CognitoOidcSsoLoginCredentials> {
  await CREDENTIALS.getPromise();
  return CREDENTIALS;
}
