import { Schema as ProfilesConfig } from "@amzn/it-support-connect-profiles";

import { Configuration } from "./configuration";

/** Amazon Connect instance details. */
export interface AmazonConnectInstance {
  /** The URL for accessing the Amazon Connect CCP (Contact Control Panel). */
  readonly ccpUrl: string;

  /** The URL for logging into the Amazon Connect instance. */
  readonly loginUrl: string;

  /** The instance display name. */
  readonly name: string;

  /** The region of the Amazon Connect instance. */
  readonly region: string;
}

/** Reads and extracts information from profiles.json */
export class ProfilesReader {
  private static singleton: ProfilesReader | null = null;

  private instances: AmazonConnectInstance[] | null = null;
  private profiles: string[] | null = null;
  private profilesConfig: Promise<ProfilesConfig> | null = null;

  private constructor() {
    // no-op
  }

  /**
   * Gets the Amazon Connect instances of the current stage.
   * @param refresh A value indicating whether to refresh the internal cache.
   */
  public async getInstances(
    refresh: boolean
  ): Promise<AmazonConnectInstance[]> {
    if (!this.instances || refresh) {
      const { instances } = await this.getProfilesConfig(refresh);
      this.instances = Object.values(instances)
        .filter((i) => i.stage == Configuration.instance.stage)
        .map((i) => ({
          name: i.displayName,
          ccpUrl: i.ccpUrl,
          loginUrl: i.samlLoginUrl,
          region: i.region,
        }));
    }

    return this.instances;
  }

  /**
   * Gets the IT Support Connect profile names.
   * @param refresh A value indicating whether to refresh the internal cache
   */
  public async getProfiles(refresh: boolean): Promise<string[]> {
    if (!this.profiles || refresh) {
      const { profiles } = await this.getProfilesConfig(refresh);
      this.profiles = Object.keys(profiles);
    }

    return this.profiles;
  }

  /** Gets the singleton instance. */
  public static get instance(): ProfilesReader {
    if (ProfilesReader.singleton == null) {
      ProfilesReader.singleton = new ProfilesReader();
    }

    return ProfilesReader.singleton;
  }

  /** Gets the profiles config.  */
  private getProfilesConfig(refresh: boolean): Promise<ProfilesConfig> {
    if (!this.profilesConfig || refresh) {
      this.profilesConfig = this.loadProfilesConfig().catch((err) => {
        this.profilesConfig = null;
        throw err;
      });
    }

    return this.profilesConfig;
  }

  /** Fetches profiles.json from ITSupportConnectStaticWebsite */
  private async loadProfilesConfig(): Promise<ProfilesConfig> {
    try {
      const res = await fetch(
        `${Configuration.instance.resourcesBaseUrl.toString()}profiles.json`,
        { mode: "cors" }
      );

      if (!res.ok) {
        throw new Error(
          `Failed to load profiles configuration: received status code ${res.status}`
        );
      }

      return (await res.json()) as ProfilesConfig;
    } catch (e) {
      throw new Error(`Failed to load profiles configuration: ${e as string}`);
    }
  }
}
