import React from "react";

import { ImgBase } from "../types";

export const ImgConnectOffline: React.FC<ImgBase> = ({
  width = 400,
  height = 301,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 301"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Connect offline</title>
      <g
        id="Connect-offline"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle"
          x="0"
          y="0.387322239"
          width="400"
          height="300"
        ></rect>
        <path
          d="M279.166018,85.0871353 C312.504965,99.1795097 352.689274,96.8187623 364.659979,129.708004 C376.630684,162.597246 366.688349,187.957401 351.821975,218.135011 C344.479766,232.965719 340.741739,238.964153 327.985284,249.54658 C291.571201,279.754765 246.267913,278.729676 185.886886,278.84727 C130.328627,278.953287 95.1199542,263.975527 79.7761147,250.448389 C69.3216796,241.231753 63.4780241,226.804501 61.2485339,214.053713 C56.9799712,189.641165 63.7507948,163.306931 70.816002,132.417585 C77.9548051,101.599412 85.3143959,66.2261292 107.172381,47.0804287 C129.030365,27.8635545 165.386744,24.9454366 194.60432,35.6926514 C223.748299,46.5110398 245.753476,70.9947609 279.166018,85.0871353 Z"
          id="Path"
          fill="#F5FBFE"
          fillRule="nonzero"
          transform="translate(214.950359, 154.176201) rotate(20.000000) translate(-214.950359, -154.176201) "
        ></path>
        <g id="Group-10" transform="translate(96.000000, 94.168714)">
          <g id="Group-9">
            <path
              d="M7,0 L197,0 C200.865993,-2.48652828e-15 204,3.13400675 204,7 L204,146 C204,149.865993 200.865993,153 197,153 L7,153 C3.13400675,153 4.73447626e-16,149.865993 0,146 L0,7 C4.14730794e-16,3.13400675 3.13400675,7.10171439e-16 7,0 Z"
              id="Rectangle"
              fill="#FFFFFF"
            ></path>
            <path
              d="M7,0 L197,0 C200.865993,1.21364172e-14 204,3.13400675 204,7 L204,147 C204,150.865993 200.865993,154 197,154 L7,154 C3.13400675,154 -4.14730794e-16,150.865993 0,147 L0,7 C-4.73447626e-16,3.13400675 3.13400675,-1.78006981e-16 7,0 Z"
              id="Rectangle"
              stroke="#0073BB"
              strokeWidth="2"
            ></path>
            <rect
              id="Rectangle"
              fillOpacity="0.1"
              fill="#4A90E2"
              x="1"
              y="22"
              width="203"
              height="8"
            ></rect>
            <rect
              id="Rectangle"
              fill="#D8D8D8"
              x="65"
              y="8"
              width="74"
              height="6"
              rx="3"
            ></rect>
            <path
              d="M137,38 L191,38 C193.209139,38 195,39.790861 195,42 L195,141 C195,143.209139 193.209139,145 191,145 L137,145 C134.790861,145 133,143.209139 133,141 L133,42 C133,39.790861 134.790861,38 137,38 Z"
              id="Rectangle-Copy-8"
              fill="#EAF3FF"
              opacity="0.699358259"
            ></path>
            <g
              id="Group-5"
              transform="translate(140.000000, 68.000000)"
              stroke="#0073BB"
              strokeWidth="2"
            >
              <g id="Group-34" transform="translate(8.000000, 10.000000)">
                <path
                  d="M12.5853659,-1 C14.7364206,-1 16.7166668,-0.268961234 18.2915861,0.957831505 C19.4892906,1.89079046 20.4521773,3.11082206 21.0775384,4.51405559 C21.7352082,4.10883024 22.5012729,3.87804878 23.3170732,3.87804878 C24.542204,3.87804878 25.6542058,4.39834119 26.4580525,5.24812201 C27.2423431,6.07722916 27.7317073,7.22147906 27.7317073,8.48780488 L27.726,8.266 L27.7958336,8.27774133 C28.9884959,8.50123715 30.0784462,9.07421239 30.9496092,9.89414813 L31.1467102,10.0877013 C32.2851193,11.2540075 33,12.8606557 33,14.6341463 L33,14.6341463 L33,15.2195122 C33,17.0043349 32.2765577,18.6201885 31.1069097,19.7898365 C29.9372617,20.9594845 28.321408,21.6829268 26.5364256,21.6829268 L26.5364256,21.6829268 L21.1263914,21.6820625 L11.292883,30.7741693 L11.2920875,21.6821357 L5.46341463,21.6829268 C3.67859197,21.6829268 2.06273831,20.9594845 0.893090317,19.7898365 C-0.276557675,18.6201885 -1,17.0043349 -1,15.2195122 L-1,15.2195122 L-1,14.6341463 C-1,13.0889468 -0.457626497,11.6704853 0.446796364,10.5584218 C1.18681652,9.64850518 2.16959098,8.94420243 3.29600196,8.54330474 C3.29268293,5.72657939 4.33280189,3.40340866 6.01444674,1.72176381 C7.69609159,0.0401189654 10.0192623,-1 12.5853659,-1 Z"
                  id="Combined-Shape"
                  fill="#FFFFFF"
                ></path>
                <g id="Group-33" transform="translate(5.658537, 7.804878)">
                  <line
                    x1="6.92682927"
                    y1="2.63414634"
                    x2="2.82926829"
                    y2="5.56097561"
                    id="Line"
                    strokeLinecap="square"
                  ></line>
                  <line
                    x1="8.68292683"
                    y1="3.41463415"
                    x2="11.0243902"
                    y2="7.51219512"
                    id="Line-2"
                    strokeLinecap="square"
                  ></line>
                  <line
                    x1="12.9756098"
                    y1="7.51219512"
                    x2="16.4878049"
                    y2="4.97560976"
                    id="Line-3"
                    strokeLinecap="square"
                  ></line>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="1.85365854"
                    cy="6.34146341"
                    r="1.85365854"
                  ></circle>
                  <circle
                    id="Oval-Copy-2"
                    fill="#FFFFFF"
                    cx="7.90243902"
                    cy="1.85365854"
                    r="1.85365854"
                  ></circle>
                  <circle
                    id="Oval-Copy-3"
                    fill="#FFFFFF"
                    cx="11.4146341"
                    cy="8.48780488"
                    r="1.85365854"
                  ></circle>
                  <circle
                    id="Oval-Copy-4"
                    fill="#FFFFFF"
                    cx="17.6585366"
                    cy="4.19512195"
                    r="1.85365854"
                  ></circle>
                </g>
              </g>
            </g>
            <g id="Group-14" transform="translate(172.000000, 48.000000)">
              <circle
                id="Oval"
                fill="#D0021B"
                cx="8"
                cy="27.4372171"
                r="5"
              ></circle>
              <g
                id="Group-18"
                transform="translate(10.500000, 10.718609) scale(-1, 1) translate(-10.500000, -10.718609) translate(0.000000, 0.218609)"
              ></g>
            </g>
          </g>
          <rect
            id="Rectangle"
            fill="#0073BB"
            x="0"
            y="20"
            width="204"
            height="2"
          ></rect>
        </g>
      </g>
    </svg>
  );
};
