import "./style.scss";

import { SpaceBetween, StatusIndicator } from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import React, { useEffect, useState } from "react";

import { useSelector } from "../../state/hooks";

export const ASSIST_TRANSFER_QUICK_CONNECT = "Assist Transfer";

export const CONNECT_CHAT_WEBSOCKET_TOPIC = "aws/chat";
export const CONNECT_CHAT_FAILED_TRANSFER_EVENT =
  "application/vnd.amazonaws.connect.event.transfer.failed";

export interface ContactInfo {
  readonly contactId: string;
  readonly originalContactId: string;
  readonly type: connect.ContactType;
}

export interface ConnectWebSocketContent {
  readonly AbosoluteTime: string;
  readonly ContentType: string;
  readonly Id: string;
  readonly Type: string;
  readonly InitialContactId: string;
}

export interface ConnectWebSocketEvent {
  readonly content: string;
  readonly contentType: string;
  readonly topic: string;
}

interface TransferToConsultantPanelProps {
  readonly contactInfo: ContactInfo;
}

enum TransferState {
  Idle,
  Transferring,
  Error,
}

export const TransferToConsultantPanel: React.FC<TransferToConsultantPanelProps> = (
  props
) => {
  // Global state
  const selectedInstance = useSelector((state) => state.selectedInstance);
  // Local State
  const [expanded, setExpanded] = useState<boolean>(true); // the component is expanded on initialization
  const [displayButton, setDisplayButton] = useState<boolean>(true); // the transfer button is displayed on initialization
  const [errorMessage, setErrorMessage] = useState<string>();
  const [transferState, setTransferState] = useState<TransferState>(
    TransferState.Idle
  );

  // expand or collapse the controller
  const toggleControllerExpansion = (): void => {
    setExpanded(!expanded);
  };

  // hook to detect connect websocket events, and in case of
  useEffect(() => {
    if (props.contactInfo.type == connect.ContactType.CHAT) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const webSocketManager: any = connect.core.getWebSocketManager();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call
      webSocketManager.onMessage(
        CONNECT_CHAT_WEBSOCKET_TOPIC,
        (event: ConnectWebSocketEvent) => {
          try {
            const content = JSON.parse(
              event.content
            ) as ConnectWebSocketContent;
            // only set the state to error for Transfer fail event
            if (
              (content.InitialContactId ==
                props.contactInfo.originalContactId ||
                content.InitialContactId == props.contactInfo.contactId) &&
              content.ContentType == CONNECT_CHAT_FAILED_TRANSFER_EVENT
            ) {
              setTransferState(TransferState.Error);
            }
          } catch (err) {
            console.error("Failed to process the websocket event", event, err);
          }
        }
      );
    }
  }, []);

  // hook to set and clear error message
  useEffect(() => {
    switch (transferState) {
      case TransferState.Idle:
        // Disabling the button and showing the message when the selected instance
        // is not default, and the customer contact is voice because cross instance
        // voice transfer is not supported at the moment
        if (
          props.contactInfo.type === connect.ContactType.VOICE &&
          selectedInstance &&
          selectedInstance.name !== "Default"
        ) {
          setErrorMessage(
            "Direct to consultant transfer is not supported for voice customer contacts at this time. Thank you for your patience."
          );
          setDisplayButton(false);
        }
        break;
      case TransferState.Error:
        setErrorMessage("Failed to transfer to consultant. Please try again.");
        break;
      default:
        setErrorMessage(undefined);
        break;
    }
  }, [transferState]);

  /**
   * Method to invoke direct to agent transfer
   * @param contactId Contact which needs to be transferred
   * @param quickConnectName The name of quick connect which is being used to transfer the contact
   * @param failureCallback Callback function to invoke when the transfer fails
   */
  const transferContact = (
    contactId: string,
    quickConnectName: string,
    failureCallback: connect.SuccessFailCallback
  ) => {
    // Update state to transferring. This disables the transfer button
    setTransferState(TransferState.Transferring);

    // fetch the contact
    let contact: connect.Contact | undefined;
    connect.agent((agent) => {
      const allContacts = agent.getContacts();
      contact = allContacts.find(
        (aContact) => aContact.contactId === contactId
      );
    });

    // the contact must be defined and in the CONNECTED state to continue the transfer
    if (!contact) {
      console.error(`Contact associated with contactId ${contactId} not found`);
      failureCallback();
      return;
    } else if (contact.getState().type !== connect.ContactStateType.CONNECTED) {
      console.error(
        `Contact associated with contactId ${contactId} must in ${connect.ContactStateType.CONNECTED} state.`
      );
      failureCallback();
      return;
    }

    // If there is an ongoing voice consultation, simply remove the
    // current agent from the contact to complete the transfer.
    // This is applicable for voice consultations
    if (
      contact?.getType() === connect.ContactType.VOICE &&
      contact?.getSingleActiveThirdPartyConnection()
    ) {
      try {
        contact.getAgentConnection().destroy();
      } catch (e) {
        console.error(`Failed to remove the current agent from the contact`);
        failureCallback();
      }
      return;
    }

    connect.agent((agent) => {
      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: function (data) {
          // fetch the correct quick connect endpoint
          const endpoint = data.endpoints.find(
            (aEndpoint) => aEndpoint.name === quickConnectName
          );
          // in case the endpoint for the quick connect is not found
          if (!endpoint) {
            console.error(`Quick connect: ${quickConnectName} not found.`);
            failureCallback();
          } else {
            // initiate the transfer on the contact
            try {
              contact?.addConnection(endpoint);
              // if the contact is voice then the current agent
              // must be removed from the current contact
              if (contact?.getType() === connect.ContactType.VOICE) {
                contact?.onRefresh((refreshContact) => {
                  if (refreshContact?.getSingleActiveThirdPartyConnection()) {
                    // only remove the current agent from the contact if the third party contact is connected
                    refreshContact?.getAgentConnection().destroy();
                  }
                });
              }
            } catch (e) {
              failureCallback();
              console.error(
                `Failed to transfer the contact with contactId ${contactId}.`
              );
            }
          }
        },
        failure: () => {
          failureCallback();
          console.error(`Failed to fetch the endpoints.`);
        },
      });
    });
  };

  return (
    <div className={expanded ? "expanded-container" : "collapsed-container"}>
      {
        <div className="title-container" onClick={toggleControllerExpansion}>
          <SpaceBetween direction="horizontal" size="xxs">
            <Button
              className="awsui-visual-refresh awsui-polaris-dark-mode"
              iconName={expanded ? "caret-down-filled" : "caret-right-filled"}
              variant="icon"
            />
            <div className="container-title">
              {"Transfer to the consultant"}
            </div>
          </SpaceBetween>
        </div>
      }
      {expanded && displayButton && (
        <div className="button-container">
          {
            <Button
              onClick={() =>
                void transferContact(
                  props.contactInfo.contactId,
                  ASSIST_TRANSFER_QUICK_CONNECT,
                  () => {
                    setTransferState(TransferState.Error);
                  }
                )
              }
              className="awsui-visual-refresh awsui-polaris-dark-mode transfer-btn"
              disabled={TransferState.Transferring === transferState}
              loading={TransferState.Transferring === transferState}
            >
              Transfer
            </Button>
          }
        </div>
      )}
      {errorMessage && expanded && (
        <div className="message-container">
          <StatusIndicator type="error"></StatusIndicator>
          <div className="error-message">{errorMessage}</div>
        </div>
      )}
    </div>
  );
};
