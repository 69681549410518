import {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";

import { Configuration } from "../configuration";
import { Action } from "./actions";
import { AppState, INITIAL_STATE, PersistedAppState } from "./app-state";
import { AppStateContext, DispatchContext } from "./provider";
import { rootReducer } from "./reducers";

/** Hook that obtains a function to dispatch actions. */
export function useDispatch(): Dispatch<Action> {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) {
    throw new Error(
      "Attempted to use useDispatch without AppStateProvider. Make sure you wrap your component in an <AppStateProvider />"
    );
  }

  return dispatch;
}

/** Hook that obtains a value from the application state. */
export function useSelector<T>(selector: (state: AppState) => T): T {
  const state = useContext(AppStateContext);
  if (!state) {
    throw new Error(
      "Attempted to use useSelector without AppStateProvider. Make sure you wrap your component in an <AppStateProvider />"
    );
  }

  return selector(state);
}

/**
 * Hook that stores data in localStorage, can store strings or objects
 * @param storageKey The key used to store in localStorage
 * @param defaultValue The default value returned if the item is not found in localStorage
 */

export function useLocalStorage<T extends unknown | string>(
  storageKey: string,
  defaultValue: T
): readonly [() => T, (value: T) => void] {
  const getItem = useCallback(() => {
    const stringData = localStorage.getItem(storageKey);
    if (typeof defaultValue === "string") {
      // if defaultValue is string then T is string
      return ((stringData || "") as unknown) as T;
    }
    try {
      return stringData ? (JSON.parse(stringData) as T) : defaultValue;
    } catch {
      console.warn(
        `Failed to parse from localStorage: ${
          stringData?.toString() || "undefined"
        }`
      );
      return defaultValue;
    }
  }, [storageKey, defaultValue]);

  const setItem = useCallback(
    (value: T) => {
      localStorage.setItem(
        storageKey,
        typeof value === "string" ? value : JSON.stringify(value)
      );
    },
    [storageKey]
  );

  return [getItem, setItem] as const;
}

/**
 * Hook that initializes AppState and persists a subset of it in localStorage
 * May only persist reducers that are keys of the rootReducer
 * @param persistedReducers A list of reducer keys that should be persisted.
 * @param initialState The initialState of the App, defaults to INITIAL_STATE
 */
export function useAppState(
  persistedReducers: (keyof PersistedAppState)[],
  initialState: AppState = INITIAL_STATE
): readonly [AppState, Dispatch<Action>] {
  const [getPersistedState, setPersistedState] = useLocalStorage<
    PersistedAppState
  >(Configuration.instance.persistedAppStateKey, {
    version: initialState.version,
  });

  // The app state including persisted state
  const appState = { ...initialState, ...getPersistedState() };

  // The AppState reducer
  const [state, dispatch] = useReducer(rootReducer, appState);

  // In order to guarantee the order of persistedReducers is preserved, the value is memoized.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoPersistedReducers = useMemo(() => persistedReducers, []);

  // This effect triggers whenever a persistedValue is updated
  useEffect(
    // This effect finds all persistedReducers in AppState and saves them to localStorage
    () => {
      const persistedState = memoPersistedReducers.reduce<
        Record<string, unknown>
      >(
        (pState, rKey) => {
          pState[rKey] = state[rKey];

          return pState;
        },
        { version: state.version }
      );

      setPersistedState((persistedState as unknown) as PersistedAppState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    memoPersistedReducers.map((rKey) => state[rKey])
  );

  return [state, dispatch] as const;
}
