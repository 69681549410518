import "./style.scss";

import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { BaseFC } from "../types";
import { ICON_CONFIGS } from "./config";
import { IconName, IconSize, IconVariant } from "./types";

export interface IconOmnia extends BaseFC {
  name: IconName;
  variant?: IconVariant;
  size?: IconSize;
  text?: string;
}

export const IconOmnia: React.FC<IconOmnia> = ({
  // Icon Props
  name,
  variant = "normal",
  size = "medium",

  // Base Props
  className,
  ...props
}) => {
  const iconConfig = ICON_CONFIGS[name];
  const color = iconConfig.colors[variant];
  const height = iconConfig.heights[size];
  const width = iconConfig.widths[size];
  const Icon = iconConfig.render;

  const [currentColor, setCurrentColor] = useState(color);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const iconOmniaClassname = classNames(
    "omnia-ui-icon",
    `omnia-ui-icon-size-${size}`,
    props.onClick && "omnia-ui-icon-clickable",
    className
  );

  return (
    <div {...props} className={iconOmniaClassname}>
      <Icon
        color={currentColor}
        height={height}
        width={width}
        variant={variant}
      />
    </div>
  );
};
