import React, { ReactElement } from "react";
import { render } from "react-dom";

import ConnectWindow from "../src/private/components/WindowedCcp";

function WindowedCcp(): ReactElement {
  return (
    <div className="awsui">
      <div style={{ height: "100vh" }}>
        <ConnectWindow />
      </div>
    </div>
  );
}

render(<WindowedCcp />, document.getElementById("app"));
