import "./style.scss";

import { default as Icon } from "@amzn/awsui-components-react/polaris/icon";
import classNames from "classnames";
import React from "react";

import { BaseFC } from "../types";

export interface IconPolaris extends Icon.Props, BaseFC {}

export const IconPolaris: React.FC<IconPolaris> = ({
  //Base Props
  onClick,
  onBlur,
  className,
  children,
  // IconPolaris Props
  ...props
}) => {
  const iconPolarisClassName = classNames(
    "polaris-icon",
    onClick && "polaris-icon-clickable",
    className
  );

  return (
    <div className={iconPolarisClassName} onClick={onClick} onBlur={onBlur}>
      <Icon {...props}>{children}</Icon>
    </div>
  );
};
