import React from "react";

import { ImgBase } from "../types";

export const ImgConnectPoppedOut: React.FC<ImgBase> = ({
  width = 260,
  height = 230,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 260 230"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Connect Popped out</title>
      <g
        id="Connect-Popped-out"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-8-Copy-2">
          <path
            d="M174.014505,69.7027273 C196.865586,79.3501421 224.408595,77.734012 232.61352,100.249463 C240.818444,122.764913 234.003799,140.126071 223.814134,160.785182 C218.781659,170.938048 216.219551,175.04448 207.47606,182.289041 C182.517228,202.969083 151.465584,202.267323 110.079401,202.347826 C71.9988249,202.420404 47.866202,192.166868 37.3492734,182.906405 C30.1836257,176.596843 26.1782849,166.720176 24.6501543,157.99119 C21.7244089,141.278749 26.3652465,123.250752 31.2078597,102.104398 C36.1009168,81.0067689 41.1453055,56.7907832 56.1271401,43.6839418 C71.1089746,30.5283762 96.028255,28.5306791 116.054478,35.8880511 C136.030258,43.2941473 151.11298,60.0553125 174.014505,69.7027273 Z"
            id="Path"
            fill="#F5FBFE"
            fillRule="nonzero"
            transform="translate(130.000000, 117.000000) rotate(20.000000) translate(-130.000000, -117.000000) "
          ></path>
          <g id="Group-3" transform="translate(30.440410, 82.239791)">
            <g id="Group-10">
              <g id="Group-9">
                <path
                  d="M3.95652174,0 L111.347826,0 C113.532953,-4.01401248e-16 115.304348,1.77139512 115.304348,3.95652174 L115.304348,82.5217391 C115.304348,84.7068657 113.532953,86.4782609 111.347826,86.4782609 L3.95652174,86.4782609 C1.77139512,86.4782609 2.48804688e-15,84.7068657 0,82.5217391 L0,3.95652174 C-2.67600832e-16,1.77139512 1.77139512,2.6218473e-15 3.95652174,0 Z"
                  id="Rectangle"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M3.95652174,0 L111.347826,0 C113.532953,6.47354956e-15 115.304348,1.77139512 115.304348,3.95652174 L115.304348,83.0869565 C115.304348,85.2720831 113.532953,87.0434783 111.347826,87.0434783 L3.95652174,87.0434783 C1.77139512,87.0434783 2.04395767e-15,85.2720831 0,83.0869565 L0,3.95652174 C-7.11690042e-16,1.77139512 1.77139512,3.06593651e-15 3.95652174,0 Z"
                  id="Rectangle"
                  stroke="#0073BB"
                  strokeWidth="1.5"
                ></path>
                <rect
                  id="Rectangle"
                  fillOpacity="0.1"
                  fill="#4A90E2"
                  x="0.565217391"
                  y="12.4347826"
                  width="114.73913"
                  height="4.52173913"
                ></rect>
                <rect
                  id="Rectangle"
                  fill="#D8D8D8"
                  x="36.7391304"
                  y="4.52173913"
                  width="41.826087"
                  height="3.39130435"
                  rx="1.69565217"
                ></rect>
              </g>
              <rect
                id="Rectangle"
                fill="#0073BB"
                x="0"
                y="11.3043478"
                width="115.304348"
                height="1.5"
              ></rect>
            </g>
            <g
              id="Group"
              transform="translate(41.826087, 44.086957)"
              fill="#0073BB"
            >
              <circle
                id="Oval"
                cx="7.25362319"
                cy="7.25362319"
                r="7.25362319"
              ></circle>
              <polygon
                id="Rectangle"
                points="17.1449275 0 31.6521739 14.5072464 17.1449275 14.5072464"
              ></polygon>
              <polygon
                id="Rectangle"
                transform="translate(24.398551, 7.253623) scale(-1, 1) translate(-24.398551, -7.253623) "
                points="17.1449275 0 31.6521739 14.5072464 17.1449275 14.5072464"
              ></polygon>
            </g>
          </g>
          <g id="Group-10" transform="translate(169.483888, 70.935443)">
            <g id="Group-9">
              <path
                d="M3.95652174,0 L41.2608696,0 C43.4459962,3.48016337e-15 45.2173913,1.77139512 45.2173913,3.95652174 L45.2173913,82.5217391 C45.2173913,84.7068657 43.4459962,86.4782609 41.2608696,86.4782609 L3.95652174,86.4782609 C1.77139512,86.4782609 -6.20577588e-16,84.7068657 0,82.5217391 L0,3.95652174 C1.76488378e-16,1.77139512 1.77139512,3.06593651e-15 3.95652174,0 Z"
                id="Rectangle"
                fill="#FFFFFF"
              ></path>
              <path
                d="M3.95652174,0 L41.2608696,0 C43.4459962,-8.45490458e-16 45.2173913,1.77139512 45.2173913,3.95652174 L45.2173913,83.0869565 C45.2173913,85.2720831 43.4459962,87.0434783 41.2608696,87.0434783 L3.95652174,87.0434783 C1.77139512,87.0434783 -6.20577588e-16,85.2720831 0,83.0869565 L0,3.95652174 C1.59096874e-15,1.77139512 1.77139512,3.51002572e-15 3.95652174,0 Z"
                id="Rectangle"
                stroke="#0073BB"
                strokeWidth="1.5"
              ></path>
              <rect
                id="Rectangle"
                fillOpacity="0.1"
                fill="#4A90E2"
                x="0"
                y="12.4347826"
                width="45.2173913"
                height="4.52173913"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8D8D8"
                x="10.173913"
                y="4.52173913"
                width="24.8695652"
                height="3.39130435"
                rx="1.69565217"
              ></rect>
              <path
                d="M7.34782609,21.4782609 L37.8695652,21.4782609 C39.118209,21.4782609 40.1304348,22.4904867 40.1304348,23.7391304 L40.1304348,79.6956522 C40.1304348,80.944296 39.118209,81.9565217 37.8695652,81.9565217 L7.34782609,81.9565217 C6.0991823,81.9565217 5.08695652,80.944296 5.08695652,79.6956522 L5.08695652,23.7391304 C5.08695652,22.4904867 6.0991823,21.4782609 7.34782609,21.4782609 Z"
                id="Rectangle-Copy-8"
                fill="#EAF3FF"
                opacity="0.699358259"
              ></path>
              <g
                id="Group-5"
                transform="translate(9.043478, 38.434783)"
                stroke="#0073BB"
                strokeWidth="1.13043478"
              >
                <g id="Group-34" transform="translate(4.521739, 5.652174)">
                  <path
                    d="M7.11346766,-0.565217391 C8.32919245,-0.565217391 9.44838731,-0.152081861 10.3385276,0.541231121 C11.0155823,1.06857591 11.5598938,1.75821636 11.913408,2.55133002 C12.2851457,2.32238367 12.7181259,2.19194062 13.1792153,2.19194062 C13.8716805,2.19194062 14.5002033,2.48601893 14.9545514,2.96632983 C15.3091006,3.34113893 15.5570154,3.82989183 15.6422406,4.37680813 C16.4612241,4.67668202 17.1756625,5.16331243 17.7004096,5.7755174 C18.2979583,6.47265752 18.6521739,7.33132232 18.6521739,8.27147402 C18.6521739,9.42845875 18.2576172,10.3979935 17.6333237,11.0891757 C16.9659441,11.8280603 16.0325008,12.2555673 14.9988792,12.2555673 L14.9988792,12.2555673 L11.9402542,12.255241 L6.38283197,17.3942992 L6.3827875,12.2552686 L3.08801697,12.2555673 C2.07920416,12.2555673 1.16589557,11.8466652 0.504790179,11.1855598 C-0.156315208,10.5244544 -0.565217391,9.61114579 -0.565217391,8.60233298 C-0.565217391,7.58131983 -0.269971962,6.72125927 0.210610413,6.06585269 C0.635264616,5.48671959 1.2062209,5.06378445 1.86296983,4.82899942 C1.86108165,3.23676227 2.44897498,1.92366577 3.3994699,0.973170851 C4.34996481,0.022675937 5.66306131,-0.565217391 7.11346766,-0.565217391 Z"
                    id="Combined-Shape"
                    fill="#FFFFFF"
                  ></path>
                  <g id="Group-33" transform="translate(3.198303, 4.411453)">
                    <line
                      x1="3.91516437"
                      y1="1.48886532"
                      x2="1.59915164"
                      y2="3.14316013"
                      id="Line"
                      strokeLinecap="square"
                    ></line>
                    <line
                      x1="4.90774125"
                      y1="1.9300106"
                      x2="6.23117709"
                      y2="4.24602333"
                      id="Line-2"
                      strokeLinecap="square"
                    ></line>
                    <line
                      x1="7.3340403"
                      y1="4.24602333"
                      x2="9.31919406"
                      y2="2.81230117"
                      id="Line-3"
                      strokeLinecap="square"
                    ></line>
                    <circle
                      id="Oval"
                      fill="#FFFFFF"
                      cx="1.04772004"
                      cy="3.58430541"
                      r="1.04772004"
                    ></circle>
                    <circle
                      id="Oval-Copy-2"
                      fill="#FFFFFF"
                      cx="4.46659597"
                      cy="1.04772004"
                      r="1.04772004"
                    ></circle>
                    <circle
                      id="Oval-Copy-3"
                      fill="#FFFFFF"
                      cx="6.45174973"
                      cy="4.79745493"
                      r="1.04772004"
                    ></circle>
                    <circle
                      id="Oval-Copy-4"
                      fill="#FFFFFF"
                      cx="9.98091198"
                      cy="2.37115589"
                      r="1.04772004"
                    ></circle>
                  </g>
                </g>
              </g>
            </g>
            <rect
              id="Rectangle"
              fill="#0073BB"
              x="0"
              y="11.3043478"
              width="45.2173913"
              height="1.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};
