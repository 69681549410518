import "./style.scss";

import React, { useCallback, useEffect, useRef } from "react";

import { init, terminate } from "../../../index";
import { AmazonConnectStreamsApi } from "../../connect/api";
import { getStage } from "../config";
import { auth } from "./auth";

const ConnectWindow: React.FC<unknown> = () => {
  const connectRef = useRef<HTMLDivElement>(null);
  // The callback method to run after CCP gets initialized
  const onCcpInit = useCallback(
    (api: AmazonConnectStreamsApi) =>
      console.debug("Amazon Connect Streams API:", api),
    []
  );

  // Initialize the CCP
  const initialize = async (connectInstanceCcpUrl?: string): Promise<void> => {
    const credentials = await auth();
    init({
      ccp: {
        container: connectRef.current as HTMLDivElement,
        onCcpInit,
      },
      config: {
        credentials,
        stage: getStage() === "dev" ? "beta" : getStage(),
      },
      connectInstanceCcpUrl,
    });
  };

  useEffect(() => {
    if (connectRef.current) {
      try {
        const selectedInstanceCcpUrl = new URLSearchParams(
          window.location.search
        ).get("instance");
        selectedInstanceCcpUrl
          ? void initialize(selectedInstanceCcpUrl?.toString() || undefined)
          : void initialize();
      } catch (err) {
        console.error("Unable to create windowed CCp", err);
      }
      return terminate;
    }
  }, []);

  return <div className="tool-connect-container" ref={connectRef} />;
};

export default ConnectWindow;
