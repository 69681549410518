import "./style.scss";

import { default as Button } from "@amzn/awsui-components-react/polaris/button";
import { default as Icon } from "@amzn/awsui-components-react/polaris/icon";
import { default as Spinner } from "@amzn/awsui-components-react/polaris/spinner";
import React, { Fragment } from "react";

import { AppState } from "../../state/app-state";
import { useSelector } from "../../state/hooks";

export interface CcpLoader {
  readonly onRetryClick?: () => void;
}

/**
 * Displays a loading Screen while the Ccp is loading.
 * Displays an error screen with a retry button when ccp encounters an error.
 * "onRetryClick" is the handler that is called when the retry butotn is clicked.
 */
export const CcpLoader: React.FC<CcpLoader> = ({ onRetryClick }) => {
  const loadingOrError = useSelector((state: AppState) => state.ccpLoading);
  const ccpPopupError = useSelector((state: AppState) => state.ccpPopupError);

  return loadingOrError.loading ? (
    <div className="connect-instance-launch-loading">
      <Spinner size="big" />
      {"Loading"}
    </div>
  ) : loadingOrError.error || ccpPopupError ? (
    <div className="connect-instance-launch-error">
      <div className="connect-instance-launch-error-message">
        <Icon
          className="connect-instance-launch-error-icon"
          variant="error"
          name="status-warning"
        />
        <div>{"Failed to connect"}</div>
      </div>
      <Button
        className="connect-instance-launch-retry-button"
        variant="primary"
        onClick={onRetryClick}
      >
        {"Try again"}
      </Button>
    </div>
  ) : (
    <Fragment />
  );
};
