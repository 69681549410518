import React from "react";

import { ImgBase } from "../types";

export const ImgConnectLaunch: React.FC<ImgBase> = ({
  width = 400,
  height = 301,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 301"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Launch Connect</title>
      <defs>
        <path
          d="M169,-4.54747351e-13 C169.666667,36 171,67.6666667 173,95 C176,136 189,140 213,140 C229,140 229,123 249,118 C269,113 275,133 283,133 C291,133 292,123 303,123 C306.678383,123 309.937432,123.754801 312.777147,125.264402 C304.348284,157.689912 252.264802,190.495644 237.935808,195.620025 C193.385954,211.552084 134.715243,212.162119 77.935465,191.620966 C25.691472,172.718673 14.7288381,150.602107 4.93689909,132.642853 C2.75494452,128.640962 1.22703378,124.284028 0.238008507,119.786516 L0,120 C7.33333333,113.333333 15,111.333333 23,114 C35,118 34,130 44,133 C54,136 57,125 74,125 C91,125 97,140 117,140 C137,140 150,136 152,95 C153.333333,67.6666667 154,36 154,-4.54747351e-13 L169,-4.54747351e-13 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Launch-Connect-Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle"
          x="0"
          y="0.316776978"
          width="400"
          height="300"
        ></rect>
        <g id="Group-16" transform="translate(11.000000, -32.000000)">
          <path
            d="M277.994511,46.7758538 C310.548634,57.274855 334.151475,133.818345 346.12218,166.707587 C358.092885,199.596829 314.481588,256.354408 301.725132,266.936835 C265.311049,297.14502 210.387259,317.784831 150.006233,317.902289 C94.4479732,318.008442 76.5821559,300.97511 61.2383164,287.447972 C50.7838812,278.231335 44.9402258,263.804084 42.7107356,251.053296 C38.4421729,226.640748 34.990441,195.465256 42.0556482,164.57591 C49.1944513,133.757738 53.4045231,116.813969 80.9941781,96.1303777 C108.583833,75.4467868 136.439494,87.6558887 173.602262,81.5789917 C210.76503,75.5020947 245.440387,36.2768526 277.994511,46.7758538 Z"
            id="Mask"
            fill="#F5FBFE"
            fillRule="nonzero"
            transform="translate(193.090055, 181.451144) rotate(20.000000) translate(-193.090055, -181.451144) "
          ></path>
          <g transform="translate(28.000000, 103.316777)">
            <path
              d="M46.9289322,101.460895 L120.468037,175 L97.7341676,197.736158 C91.1258181,195.914276 84.5179347,193.81721 77.935465,191.435878 C32.3835029,174.954815 18.2146118,156.030186 8.84162656,139.54821 L46.9289322,101.460895 Z"
              id="Combined-Shape"
              fill="#C5E1EE"
              fillRule="nonzero"
            ></path>
            <path
              d="M114,72.3898269 L194.610173,153 L141.690606,205.921487 C120.734509,203.856012 99.2023495,199.129573 77.935465,191.435878 C74.4379685,190.170456 71.1254823,188.890628 67.985972,187.598607 L33.3898269,153 L114,72.3898269 Z"
              id="Combined-Shape"
              fill="#E4F3FA"
              fillRule="nonzero"
            ></path>
            <path
              d="M255,84.3898269 L307.760466,137.14854 C291.96971,165.24128 250.481599,190.948271 237.935808,195.434937 C229.742981,198.364881 221.072585,200.776633 212.024208,202.633938 L174.389827,165 L255,84.3898269 Z"
              id="Combined-Shape"
              fill="#E4F3FA"
              fillRule="nonzero"
            ></path>
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <use
              id="Combined-Shape"
              fill="#FFFFFF"
              fillRule="nonzero"
              xlinkHref="#path-1"
            ></use>
            <path
              d="M286,178 L286.00047,195.225325 C273.578847,206.620498 259.458427,216.17528 249.115296,222.002035 L74.0639432,222.000904 C48.1414684,212.156663 32.9619757,201.504504 22.9987771,191.06013 L23,178 L286,178 Z"
              id="Combined-Shape"
              fill="#A1CBE6"
              fillRule="nonzero"
              mask="url(#mask-2)"
            ></path>
          </g>
        </g>
        <g id="Group-3" transform="translate(161.000000, 23.316777)">
          <path
            d="M15.6946614,50.052457 L15.9999979,68.0083756 C15.9977644,72.368283 14.2516935,76.3203819 11.4214301,79.2049582 C8.8031554,81.8734778 5.25706096,83.6285361 1.3053386,83.947543 L1.3053386,83.947543 L1.00000215,65.9916244 C1.00223562,61.631717 2.74830646,57.6796181 5.57856985,54.7950418 C8.1968446,52.1265222 11.742939,50.3714639 15.6946614,50.052457 L15.6946614,50.052457 Z"
            id="Rectangle"
            stroke="#0073BB"
            strokeWidth="2"
            fill="#E4F3FA"
          ></path>
          <path
            d="M78.6946614,50.052457 L78.9999979,68.0083756 C78.9977644,72.368283 77.2516935,76.3203819 74.4214301,79.2049582 C71.8031554,81.8734778 68.257061,83.6285361 64.3053386,83.947543 L64.3053386,83.947543 L64.0000021,65.9916244 C64.0022356,61.631717 65.7483065,57.6796181 68.5785699,54.7950418 C71.1968446,52.1265222 74.742939,50.3714639 78.6946614,50.052457 L78.6946614,50.052457 Z"
            id="Rectangle"
            stroke="#0073BB"
            strokeWidth="2"
            fill="#E4F3FA"
            transform="translate(71.500000, 67.000000) scale(-1, 1) translate(-71.500000, -67.000000) "
          ></path>
          <path
            d="M40.0770305,1 C45.4955261,1 50.3634534,6.14239176 54.2763327,12.8682255 C59.4789341,21.8109578 62.9742619,33.7902457 63.5134304,42.0650202 C64.0327574,50.0352812 64.136165,59.0680404 63.8247781,69.1617651 L63.8247781,69.1617651 L63.7853213,70.381104 C63.7243334,72.18248 63.0647307,73.85221 61.9819854,75.1757917 C60.8992401,76.4993733 59.3933521,77.4768065 57.6397936,77.8935887 C51.7328995,79.2975272 45.8530068,80 40,80 C34.4619806,80 28.9240735,79.3711 23.3923903,78.1153545 L23.3923903,78.1153545 L22.4082481,77.8853878 C20.6752722,77.4688368 19.1846369,76.5041188 18.1072787,75.1984023 C17.0299206,73.8926858 16.3658395,72.2459707 16.285972,70.4654254 C15.8105139,59.8656841 15.9275506,50.405593 16.6391098,42.085209 C17.3536815,33.7295996 20.8718332,21.6340464 26.0866081,12.670389 C29.9411776,6.04478377 34.7102547,1 40.0770305,1 Z"
            id="Rectangle"
            stroke="#0073BB"
            strokeWidth="2"
            fill="#FFFFFF"
          ></path>
          <circle
            id="Oval"
            fill="#E4F3FA"
            cx="40.5"
            cy="49.5"
            r="17.5"
          ></circle>
          <g
            id="Group-10"
            transform="translate(26.000000, 35.000000)"
            stroke="#0073BB"
            strokeWidth="1.25"
          >
            <g id="Group-5" transform="translate(0.000000, 0.068315)">
              <g id="Group-34" transform="translate(5.000000, 6.250000)">
                <path
                  d="M7.86585366,-0.625 C9.21025088,-0.625 10.4478946,-0.168108938 11.4322149,0.598624117 C12.1807775,1.18171449 12.7825822,1.94422309 13.173537,2.82130353 C13.5847059,2.56792399 14.0634179,2.42378049 14.5731707,2.42378049 C15.3388775,2.42378049 16.0338786,2.74896324 16.5362828,3.28007626 C16.9252508,3.69127094 17.1981522,4.22648827 17.2944195,4.8253617 C18.164985,5.12158448 18.9610008,5.65324158 19.5540275,6.34510606 C20.2154655,7.11678383 20.625,8.08449141 20.625,9.14634146 C20.625,10.4256996 20.1887114,11.4977813 19.4983868,12.2620693 C18.7604189,13.0791051 17.7282461,13.5518293 16.5852229,13.5518293 L16.5852229,13.5518293 L13.2036233,13.551056 L7.05819827,19.2338391 L7.05711874,13.5511214 L3.41463415,13.5518293 C2.29911998,13.5518293 1.28921144,13.0996778 0.558181448,12.3686478 C-0.172848547,11.6376178 -0.625,10.6277093 -0.625,9.51219512 C-0.625,8.3832553 -0.298570876,7.43228016 0.232776731,6.70757399 C0.70236345,6.06710363 1.33376123,5.59938345 2.0600111,5.33974012 C2.05792683,3.57911212 2.70800118,2.12713041 3.75902921,1.07610238 C4.81005724,0.0250743534 6.26203895,-0.625 7.86585366,-0.625 Z"
                  id="Combined-Shape"
                  fill="#FFFFFF"
                ></path>
                <g id="Group-33" transform="translate(3.536585, 4.878049)">
                  <line
                    x1="4.32926829"
                    y1="1.64634146"
                    x2="1.76829268"
                    y2="3.47560976"
                    id="Line"
                    strokeLinecap="square"
                  ></line>
                  <line
                    x1="5.42682927"
                    y1="2.13414634"
                    x2="6.8902439"
                    y2="4.69512195"
                    id="Line-2"
                    strokeLinecap="square"
                  ></line>
                  <line
                    x1="8.1097561"
                    y1="4.69512195"
                    x2="10.304878"
                    y2="3.1097561"
                    id="Line-3"
                    strokeLinecap="square"
                  ></line>
                  <circle
                    id="Oval"
                    fill="#FFFFFF"
                    cx="1.15853659"
                    cy="3.96341463"
                    r="1.15853659"
                  ></circle>
                  <circle
                    id="Oval-Copy-2"
                    fill="#FFFFFF"
                    cx="4.93902439"
                    cy="1.15853659"
                    r="1.15853659"
                  ></circle>
                  <circle
                    id="Oval-Copy-3"
                    fill="#FFFFFF"
                    cx="7.13414634"
                    cy="5.30487805"
                    r="1.15853659"
                  ></circle>
                  <circle
                    id="Oval-Copy-4"
                    fill="#FFFFFF"
                    cx="11.0365854"
                    cy="2.62195122"
                    r="1.15853659"
                  ></circle>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
