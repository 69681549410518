import {
  ChatTranscript,
  ChatTranscriptMessage,
  ChatTranscriptParticipant,
} from "../../chat-transcripts";

export interface NewChatTranscriptImplOptions {
  /** The contact id. */
  readonly contactId: string;

  /** The initial contact id. */
  readonly initialContactId: string;

  /** The messages of the chat transcript. */
  readonly messages: ChatTranscriptMessage[];
}

export interface SetInitialMessagesSucceededState {
  /** Indicates whether obtaining the initial messages associated with the chat transcript succeeded. */
  initialMessagesSucceeded: boolean;
}

export interface SetSessionEndedState {
  /** Indicates whether the chat session associated with the chat transcript has ended. */
  sessionEnded: true;
}

interface ChatTranscriptImplState {
  initialMessagesSucceeded?: boolean;
  sessionEnded: boolean;
}

/** Implements a chat transcript that can add new messages. */
export class ChatTranscriptImpl implements ChatTranscript {
  public readonly Version: "2019-08-26";
  public readonly AWSAccountId: string;
  public readonly InstanceId: string;
  public readonly InitialContactId: string;
  public readonly ContactId: string;
  public readonly Participants: ChatTranscriptParticipant[];
  public readonly Transcript: ChatTranscriptMessage[];

  private readonly messageIds: Set<string>;
  private readonly participantIds: Set<string>;
  private readonly state: ChatTranscriptImplState;

  /**
   * Creates a new instance of the `ChatTranscriptImpl` class.
   * @param options The chat transcript options or a JSON string of a `ChatTranscript`
   */
  constructor(options: ChatTranscript | NewChatTranscriptImplOptions) {
    let messages: ChatTranscriptMessage[];
    let state: ChatTranscriptImplState;
    if ("messages" in options) {
      // NewChatTranscriptImplOptions
      this.Version = "2019-08-26";
      this.AWSAccountId = ""; // not available
      this.InstanceId = ""; // not available
      this.InitialContactId = options.initialContactId;
      this.ContactId = options.contactId;

      messages = options.messages;
      state = { sessionEnded: false };
    } else {
      // ChatTranscript (persisted)
      this.Version = options.Version;
      this.AWSAccountId = options.AWSAccountId;
      this.InstanceId = options.InstanceId;
      this.InitialContactId = options.InitialContactId;
      this.ContactId = options.ContactId;

      messages = options.Transcript;
      state = { initialMessagesSucceeded: true, sessionEnded: true };
    }

    this.Participants = [];
    this.participantIds = new Set();
    this.Transcript = [];
    this.messageIds = new Set();

    this.executeAddMessages(messages, false);
    this.state = state;
  }

  /** Whether the chat transcript processing has finished. */
  public get finished(): boolean {
    return (
      this.state.initialMessagesSucceeded !== undefined &&
      this.state.sessionEnded
    );
  }

  /** Indicates whether obtaining the initial messages associated with the chat transcript succeeded. */
  public get initialMessagesSucceeded(): boolean | undefined {
    return this.state.initialMessagesSucceeded;
  }

  /** Whether the contact of this chat transcript was transferred. */
  public get transferred(): boolean {
    if (this.Transcript.length < 2) {
      return false;
    }

    // successful contact transfers have the following sequence of events:
    // { ContentType: "application/vnd.amazonaws.connect.event.transfer.succeeded" }
    const transferSucceed = this.Transcript[this.Transcript.length - 2];
    // { ContentType: "application/vnd.amazonaws.connect.event.participant.left", ParticipantRole: "AGENT" }
    const participantLeft = this.Transcript[this.Transcript.length - 1];
    return (
      transferSucceed.ContentType ===
        "application/vnd.amazonaws.connect.event.transfer.succeeded" &&
      participantLeft.ContentType ===
        "application/vnd.amazonaws.connect.event.participant.left" &&
      participantLeft.ParticipantRole === "AGENT"
    );
  }

  /**
   * Adds one or more messages to the chat transcript.
   * Duplicate messages are ignored, and unique participants are extracted.
   * @param messages The messages to add
   */
  public addMessages(...messages: ChatTranscriptMessage[]): void {
    this.executeAddMessages(messages, true);
  }

  /**
   * Updates the state of the chat transcript.
   * Returns whether the chat transcript processing has finished.
   * @param state The state to set
   */
  public setState(state: SetInitialMessagesSucceededState): boolean;
  public setState(state: SetSessionEndedState): boolean;
  public setState(
    state: SetInitialMessagesSucceededState | SetSessionEndedState
  ): boolean {
    if ("initialMessagesSucceeded" in state) {
      if (this.state.initialMessagesSucceeded !== undefined) {
        throw new Error(
          `Initial messages succeeded already set for transcript: ${this.ContactId}`
        );
      }

      this.state.initialMessagesSucceeded = state.initialMessagesSucceeded;
    } else {
      this.state.sessionEnded = state.sessionEnded;
    }

    return this.finished;
  }

  /** Return the chat transcript object for `JSON.stringify()`. */
  public toJSON(): ChatTranscript {
    return {
      Version: this.Version,
      AWSAccountId: this.AWSAccountId,
      InstanceId: this.InstanceId,
      InitialContactId: this.InitialContactId,
      ContactId: this.ContactId,
      Participants: this.Participants,
      Transcript: this.Transcript,
    };
  }

  private executeAddMessages(
    messages: ChatTranscriptMessage[],
    checkFinished: boolean
  ): void {
    if (checkFinished && this.finished) {
      throw new Error(
        `Cannot add messages to finished transcript: ${this.ContactId}`
      );
    }

    let sort = false;
    for (const m of messages) {
      // ignore message if duplicated
      if (this.messageIds.has(m.Id)) {
        continue;
      }

      // determine if sorting is necessary
      if (!sort && this.Transcript.length > 0) {
        const last = this.Transcript[this.Transcript.length - 1];
        if (m.AbsoluteTime < last.AbsoluteTime) {
          sort = true;
        }
      }

      // add the message
      this.messageIds.add(m.Id);
      this.Transcript.push(m);

      // add participant if first time encountered
      if (m.ParticipantId && !this.participantIds.has(m.ParticipantId)) {
        this.participantIds.add(m.ParticipantId);
        this.Participants.push({ ParticipantId: m.ParticipantId });
      }
    }

    if (sort) {
      this.Transcript.sort((x, y) =>
        x.AbsoluteTime > y.AbsoluteTime
          ? 1
          : x.AbsoluteTime < y.AbsoluteTime
          ? -1
          : 0
      );
    }
  }
}
