import { ChatTranscript } from "../../chat-transcripts";
import { ChatTranscriptImpl } from "./chat-transcript-impl";

export const STORAGE_KEY = "agent.js.transcripts";

/**
 * Manages persistance of the chat transcripts.
 *
 * **IMPORTANT:** No more than one instance of this class should be used at the same time.
 * This will result in loss of data.
 */
export class ChatTranscriptPersistenceManager {
  private readonly transcripts: ChatTranscriptImpl[];

  /**
   * Initializes a new instance of the `ChatTranscriptPersistenceManager` class.
   * @param maxTranscripts The maximum amount of chat transcripts to persist.
   */
  constructor(public readonly maxTranscripts: number = 50) {
    const json = localStorage.getItem(STORAGE_KEY);
    const rawTranscripts: ChatTranscript[] = (json
      ? JSON.parse(json)
      : []) as ChatTranscript[];
    this.transcripts = rawTranscripts.map((t) => new ChatTranscriptImpl(t));
  }

  /** Gets the persisted chat transcripts. */
  public get(): Record<string, ChatTranscriptImpl> {
    return this.transcripts.reduce<Record<string, ChatTranscriptImpl>>(
      (r, t) => {
        r[t.ContactId] = t;
        return r;
      },
      {}
    );
  }

  /**
   * Persists a chat transcript.
   * @param transcript The transcript to persist.
   * @returns The evicted transcript if the maximum number of transcripts is exceeded, otherwise `undefined`.
   */
  public persist(
    transcript: ChatTranscriptImpl
  ): ChatTranscriptImpl | undefined {
    const result =
      this.transcripts.length === this.maxTranscripts
        ? this.transcripts.shift()
        : undefined;

    this.transcripts.push(transcript);

    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.transcripts));

    return result;
  }
}
