import { IconConnect, IconGlobe, IconPopIn, IconPopOut } from "./Base";
import { IconBase, IconName, IconSize, IconVariant } from "./types";

export const DEFAULT_ICON_COLORS: Record<IconVariant, string> = {
  // Grey
  normal: "#879596",
  // Dark Grey
  alternate: "#545B64",
  // Light Grey
  inactive: "#CCCCCC",
  // Blue
  active: "#0073BB",
  // Red
  error: "#D0021B",
  // Light Orange
  warning: "#F09436",
  // Green
  success: "#7ED321",
};

export const DEFAULT_ICON_HOVER_COLORS: Record<IconVariant, string> = {
  // Grey
  normal: "#B2B2B2",
  // Dark Grey
  alternate: "#40454D",
  // Light Grey
  inactive: "#B2B2B2",
  // Blue
  active: "#126498",
  // Red
  error: "#FF001F",
  // Light Orange
  warning: "#E6A35E",
  // Green
  success: "#28B502",
};

export const DEFAULT_ICON_HEIGHTS: Record<IconSize, string> = {
  small: "16px",
  medium: "24px",
  large: "36px",
  none: "",
};

export const DEFAULT_ICON_WIDTHS: Record<IconSize, string> = {
  small: "16px",
  medium: "24px",
  large: "36px",
  none: "",
};

const defaultIconConfig = {
  colors: DEFAULT_ICON_COLORS,
  hoverColors: DEFAULT_ICON_HOVER_COLORS,
  heights: DEFAULT_ICON_HEIGHTS,
  widths: DEFAULT_ICON_WIDTHS,
};

export interface IconConfig {
  render: React.FC<IconBase>;
  colors: Record<IconVariant, string>;
  hoverColors: Record<IconVariant, string>;
  heights: Record<IconSize, string>;
  widths: Record<IconSize, string>;
}

export const ICON_CONFIGS: Record<IconName, IconConfig> = {
  ["connect"]: {
    ...defaultIconConfig,
    render: IconConnect,
  },
  ["globe"]: {
    ...defaultIconConfig,
    render: IconGlobe,
  },
  ["pop-in"]: {
    ...defaultIconConfig,
    render: IconPopIn,
  },
  ["pop-out"]: {
    ...defaultIconConfig,
    render: IconPopOut,
  },
};
