import { default as Button } from "@amzn/awsui-components-react/polaris/button";
import { default as Icon } from "@amzn/awsui-components-react/polaris/icon";
import { default as Spinner } from "@amzn/awsui-components-react/polaris/spinner";
import React, {
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
} from "react";

import { ProfilesReader } from "../../profiles-reader";
import {
  setInstances,
  setProfilesConfigLoadingState,
  setSelectedInstance,
} from "../../state/actions";
import { useDispatch, useSelector } from "../../state/hooks";

export const MainLoader: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const loadingOrError = useSelector((state) => state.profilesConfigLoading);
  const instances = useSelector((state) => state.instances);
  const selectedInstance = useSelector((state) => state.selectedInstance);

  // callback to load instances
  const loadInstances = useCallback(
    async (refresh?: boolean) => {
      try {
        // TODO https://i.amazon.com/issues/CONC-7534 Allow refreshing instances
        dispatch(setProfilesConfigLoadingState({ loading: true }));
        const instances = await ProfilesReader.instance.getInstances(!!refresh);
        dispatch(setInstances(instances));
      } catch (err) {
        dispatch(setProfilesConfigLoadingState({ error: err as Error }));
      }
    },
    [dispatch]
  );

  // on mount: load the instances
  useEffect(() => {
    void loadInstances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when instances are no longer undefined: validate selectedInstance
  useEffect(() => {
    if (instances.length > 0 && selectedInstance) {
      // If selectedInstance is invalid, set selectedInstance to undefined
      if (!instances.find((i) => i.ccpUrl === selectedInstance.ccpUrl)) {
        dispatch(setSelectedInstance());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instances]);

  return loadingOrError.loading ? (
    <div className="connect-profiles-loading">
      <Spinner />
      {"Loading"}
    </div>
  ) : loadingOrError.error ? (
    <div className="connect-profiles-error">
      <Icon
        className="connect-profiles-error-icon"
        variant="error"
        name="status-warning"
      />
      <div className="connect-profiles-error-message">
        {"Something went wrong. An error occured while loading Amazon Connect"}
      </div>
      <Button
        variant="primary"
        onClick={(): void => {
          void loadInstances(true);
        }}
      >
        {"Try again"}
      </Button>
    </div>
  ) : (
    <Fragment>{children}</Fragment>
  );
};
