import React from "react";

import { BaseFC } from "../types";
import { IMG_CONFIGS } from "./config";
import { ImgName } from "./types";

export interface ImgOmnia extends BaseFC {
  name: ImgName;
  width?: string;
  height?: string;
}

export const ImgOmnia: React.FC<ImgOmnia> = ({
  // Image Props
  name,
  width,
  height,

  // Base Props
  className,
  ...props
}) => {
  const imgConfig = IMG_CONFIGS[name];
  const Img = imgConfig.render;

  return (
    <div {...props} className={className}>
      <Img height={height} width={width} />
    </div>
  );
};
