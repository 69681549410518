import "./style.scss";

import {
  ButtonDropdown,
  ButtonDropdownProps,
  SpaceBetween,
  Spinner,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";
import Button from "@amzn/awsui-components-react-v3/polaris/button";
import CopyToClipboard from "@amzn/awsui-components-react-v3/polaris/copy-to-clipboard";
import { ExternalSession } from "@amzn/it-support-connect-api-model";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";

import { useSelector } from "../../state/hooks";
import { endSession, fetchExternalSessions, startSession } from "./api";
import { ITS_MESSAGE_EXTERNAL_SESSION_STRINGS } from "./constants";

export interface ContactInfo {
  readonly contactId: string;
  readonly originalContactId: string;
  readonly contactType: connect.ContactType;
  readonly language: string | undefined;
}

export type BomgarPortal = "DEFAULT" | "ACCESSIBILITY";

interface ExternalSessionPanelProps {
  readonly contactInfo: ContactInfo;
  readonly sendMessage: (
    contactId: string,
    message: string,
    contentType: connect.ChatMessageContentType
  ) => void;
}

enum LoadStatus {
  Loaded,
  Loading,
  Error,
}

export const ExternalSessionPanel: React.FC<ExternalSessionPanelProps> = (
  props
) => {
  // Local State
  const [expanded, setExpanded] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [warningMessage, setWarningMessage] = useState<string>();
  const [activeSession, setActiveSession] = useState<ExternalSession>();
  const [channelInCreation, setChannelInCreation] = useState<boolean>(false);
  const [terminationInProgress, setTerminationInProgress] = useState<boolean>(
    false
  );
  const [loadStatus, setLoadStatus] = useState<LoadStatus>(LoadStatus.Loaded);
  const [bomgarPortal, setBomgarPortal] = useState<BomgarPortal>("DEFAULT");

  // Refs
  const sessionUrlExpiryTimer: MutableRefObject<number | undefined> = useRef(
    undefined
  );
  const sendSessionDetails: MutableRefObject<boolean> = useRef(false);

  // Constants
  const bomgarPortalItems: ButtonDropdownProps.Item[] = [
    {
      text: `Default`,
      id: "DEFAULT",
      disabled: channelInCreation,
    },
    {
      text: `Accessibility`,
      id: "ACCESSIBILITY",
      disabled: channelInCreation,
    },
  ];

  // Timeout for checking session key expiry
  const setTimeoutUrlExpiryActions = () => {
    // Compute time to expiry of the key
    const expiresInMs =
      new Date(activeSession?.externalSessionKeyExpiry as string).getTime() -
      Date.now();

    // Set timeout to trigger the expiry check when session has SESSION_URL_GENERATED status
    if (activeSession?.status === "SESSION_URL_GENERATED") {
      sessionUrlExpiryTimer.current = window.setTimeout(() => {
        // setTimeout function cannot be marked as async, so do the following workaround
        const checkSession = async () => {
          const session = await fetchExternalSessions(
            props.contactInfo.contactId,
            props.contactInfo.originalContactId
          );
          /**
           * The callback called after session link expiry. If session.length = 0, then
           * the link has expired. The session cannot have been ended because the timeout
           * timer is removed when activeSession state is set to undefined by `endExternalSession`.
           * If session has started, then session.length > 0 and this warning will not show.
           */
          if (session.length === 0) {
            setSuccessMessage(undefined);
            setActiveSession(undefined);
            setWarningMessage(
              "The session key was not used and has expired. To continue with external sessions, generate a new key."
            );
          }
        };

        // Call function and catch/log errors
        checkSession().catch((err) => console.error(err));
      }, expiresInMs + 1000); // Give an extra second so the key is definitely expired
    } else {
      sessionUrlExpiryTimer.current = undefined;
    }
  };

  // To return the localized string
  const getLocalizedString = (): string => {
    return props.contactInfo.language &&
      ITS_MESSAGE_EXTERNAL_SESSION_STRINGS[props.contactInfo.language]
      ? ITS_MESSAGE_EXTERNAL_SESSION_STRINGS[props.contactInfo.language]
      : ITS_MESSAGE_EXTERNAL_SESSION_STRINGS["eng"];
  };

  useEffect(() => {
    // If setting a new active session, create new timeouts
    if (activeSession) {
      setTimeoutUrlExpiryActions();
    }

    // If session was just created, send the sessionUrlLink
    if (activeSession && sendSessionDetails.current) {
      props.sendMessage(
        props.contactInfo.contactId,
        `${getLocalizedString()} \n\n ${activeSession.externalSessionKeyUrl!}`,
        "text/plain"
      );
      sendSessionDetails.current = false;
    }

    // Cleanup function for this effect: clear the existing timeouts
    return () => {
      if (sessionUrlExpiryTimer.current) {
        clearTimeout(sessionUrlExpiryTimer.current);
        sessionUrlExpiryTimer.current = undefined;
      }
    };
  }, [activeSession]);

  // expand or collapse the controller
  const toggleControllerExpansion = (): void => {
    setExpanded(!expanded);
  };

  // fetch existing external sessions on page load
  const getExternalSessions = async (): Promise<void> => {
    setLoadStatus(LoadStatus.Loading);
    setExpanded(false);
    try {
      const externalSessions = await fetchExternalSessions(
        props.contactInfo.contactId,
        props.contactInfo.originalContactId
      );
      if (externalSessions.length > 0) {
        setActiveSession(externalSessions[0]);
        if (externalSessions[0].status === "SESSION_URL_GENERATED") {
          setSuccessMessage(
            getSessionCreateSuccessMessage(externalSessions[0])
          );
        } else if (externalSessions[0].status === "STARTED") {
          setSuccessMessage("Remote session in progress");
        }
      } else {
        setActiveSession(undefined);
      }

      setLoadStatus(LoadStatus.Loaded);
    } catch (error) {
      setLoadStatus(LoadStatus.Error);
    }
  };

  // generate the session key generation message based on the type of contact
  const getSessionCreateSuccessMessage = (session: ExternalSession): string => {
    const localExpiryTime = new Date(
      session.externalSessionKeyExpiry!
    ).toLocaleTimeString();

    if (props.contactInfo.contactType === connect.ContactType.CHAT) {
      return `The session url has been sent to the customer. It is valid until ${localExpiryTime}. \n\n The 12-digit session key may also be used to start a session.`;
    } else if (props.contactInfo.contactType === connect.ContactType.VOICE) {
      return `Please copy and send the session key to the customer. It is valid until ${localExpiryTime}.`;
    }
    return `Successfully generated session key: ${session.externalSessionKeyUrl!}.`;
  };

  const startExternalSession = async (): Promise<void> => {
    setChannelInCreation(true);
    setErrorMessage(undefined);
    setSuccessMessage(undefined);
    setWarningMessage(undefined);

    try {
      const session: ExternalSession = await startSession(
        props.contactInfo.contactId,
        { portal: bomgarPortal }
      );
      setActiveSession({ ...session });
      sendSessionDetails.current = true;
      setSuccessMessage(getSessionCreateSuccessMessage(session));
    } catch (err) {
      const error = err as Error;
      setErrorMessage(error.message);
    }
    setChannelInCreation(false);
  };

  const endExternalSession = async (): Promise<void> => {
    if (activeSession) {
      setTerminationInProgress(true);
      setSuccessMessage(undefined);
      setErrorMessage(undefined);
      setWarningMessage(undefined);
      try {
        await endSession(
          props.contactInfo.contactId,
          activeSession.startedTimestamp,
          props.contactInfo.originalContactId
        );
        setActiveSession(undefined);
        setSuccessMessage("Successfully ended active session.");
      } catch (err) {
        setErrorMessage(`Failed to end the active session.`);
      }
      setTerminationInProgress(false);
    }
  };

  // ends an external session
  const endActiveExternalSession = (): void => {
    void endExternalSession();
  };

  // fetch external sessions when the component loads
  // note: when popped out, ccp creates a new window and sets contact id, which runs the following as well.
  useEffect(() => {
    void getExternalSessions();
  }, [props.contactInfo.contactId]);

  // fetch external session when ccp is popped in only
  const isCcpPoppedOutOfWindow = useSelector((state) => state.ccpInPopup);
  useEffect(() => {
    if (!isCcpPoppedOutOfWindow) {
      void getExternalSessions();
    }
  }, [isCcpPoppedOutOfWindow]);

  const getMainActionItem = (): ButtonDropdownProps.Item => {
    return bomgarPortalItems.find(
      (item) => item.id === bomgarPortal
    ) as ButtonDropdownProps.Item;
  };

  const getButtonDropdownItems = (): ButtonDropdownProps.Item[] => {
    return bomgarPortalItems.filter((item) => item.id !== bomgarPortal);
  };

  const setMainAction = (
    event: CustomEvent<ButtonDropdownProps.ItemClickDetails>
  ) => {
    setBomgarPortal(event.detail.id as BomgarPortal);
  };

  return (
    <div
      className={
        expanded
          ? "external-session-expanded-container"
          : "external-session-collapsed-container"
      }
    >
      {loadStatus === LoadStatus.Loading && (
        <div className="external-session-title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="external-session-spinner">
              <Spinner variant="inverted"></Spinner>
            </div>
            <div className="external-session-container-title">
              Loading external sessions...
            </div>
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Error && (
        <div className="external-session-title-container">
          <SpaceBetween direction="horizontal" size="xxs">
            <div className="external-session-error-icon">
              <StatusIndicator type="error"></StatusIndicator>
            </div>
            <div className="external-session-container-title">
              Failed to load external sessions.
            </div>
            <Button
              iconName="refresh"
              variant="icon"
              className="awsui-visual-refresh awsui-polaris-dark-mode retry"
              onClick={() => void getExternalSessions()}
            />
          </SpaceBetween>
        </div>
      )}
      {loadStatus === LoadStatus.Loaded && (
        <div
          className="external-session-title-container"
          onClick={toggleControllerExpansion}
        >
          <SpaceBetween direction="horizontal" size="xxs">
            <Button
              className="awsui-visual-refresh awsui-polaris-dark-mode"
              iconName={expanded ? "caret-down-filled" : "caret-right-filled"}
              variant="icon"
              data-testid="external-session-panel-dropdown"
            />
            <div className="external-session-container-title">
              {activeSession ? "End external session" : "Generate session key"}
            </div>
          </SpaceBetween>
        </div>
      )}
      {expanded && !activeSession && (
        <div className="external-session-message-container">
          <div className="external-session-info-message">
            Select the public portal and click to generate session key.
          </div>
        </div>
      )}
      {expanded && (
        <div className="external-session-button-container">
          {activeSession && (
            <SpaceBetween direction="horizontal" size="xxs">
              <Button
                onClick={endActiveExternalSession}
                className="awsui-visual-refresh awsui-polaris-dark-mode end-session-btn"
                disabled={terminationInProgress}
                loading={terminationInProgress}
              >
                End session
              </Button>
              {!terminationInProgress && (
                <CopyToClipboard
                  copyButtonText="Session key"
                  copyButtonAriaLabel="Copy session key"
                  copyErrorText="Session key failed to copy"
                  copySuccessText={`Copied session key: ${activeSession.externalSessionShortKey!}`}
                  className="awsui-visual-refresh awsui-polaris-dark-mode copy-session-url-btn"
                  textToCopy={activeSession.externalSessionShortKey!}
                />
              )}
            </SpaceBetween>
          )}
          {!activeSession && (
            <div>
              <ButtonDropdown
                variant="normal"
                className={`awsui-visual-refresh awsui-polaris-dark-mode bomgar-btn`}
                mainAction={{
                  text: getMainActionItem().text,
                  onClick: () => void startExternalSession(),
                  disabled: channelInCreation,
                  loading: channelInCreation,
                }}
                items={getButtonDropdownItems()}
                onItemClick={(event) => setMainAction(event)}
              />
            </div>
          )}
        </div>
      )}
      {successMessage && expanded && (
        <div className="external-session-message-container">
          <StatusIndicator type="success"></StatusIndicator>
          <div className="external-session-success-message">
            {successMessage}
          </div>
        </div>
      )}
      {errorMessage && expanded && (
        <div className="external-session-message-container">
          <StatusIndicator type="error"></StatusIndicator>
          <div className="external-session-error-message">{errorMessage}</div>
        </div>
      )}
      {warningMessage && expanded && (
        <div className="external-session-message-container">
          <StatusIndicator type="warning"></StatusIndicator>
          <div className="external-session-warning-message">
            {warningMessage}
          </div>
        </div>
      )}
    </div>
  );
};
