import { MutableRefObject, useEffect, useRef } from "react";

/** Hook that lets you if the component is still mounted. */
export function useMounted(): MutableRefObject<boolean> {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return (): void => {
      mounted.current = false;
    };
  });

  return mounted;
}
