/** Convenient console logging functions */
export const logger = {
  debug: (message: string, ...args: unknown[]) => {
    console.error(
      `[${new Date().toISOString()}] [DEBUG] [ITSupportConnectAgentClient]: ${message}`,
      ...args
    );
  },
  info: (message: string, ...args: unknown[]) => {
    console.info(
      `[${new Date().toISOString()}] [INFO] [ITSupportConnectAgentClient]: ${message}`,
      ...args
    );
  },
  error: (message: string, ...args: unknown[]) => {
    console.error(
      `[${new Date().toISOString()}] [ERROR] [ITSupportConnectAgentClient]: ${message}`,
      ...args
    );
  },
};
