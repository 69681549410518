import React from "react";

import { ImgBase } from "../types";

export const ImgOfflineNoQueues: React.FC<ImgBase> = ({
  width = 400,
  height = 300,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 400 300"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Offline - No Queue</title>
      <defs>
        <circle id="path-1" cx="45" cy="45" r="45"></circle>
      </defs>
      <g
        id="Offline---No-Queue"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect id="Rectangle" x="0" y="0" width="400" height="300"></rect>
        <path
          d="M304.141845,44.3859407 C330.310657,66.0583834 344.395275,95.9526642 351.104577,132.332154 C357.813879,168.711644 350.253311,205.740678 331.935142,231.897074 C313.554667,258.053471 287.709886,261.036536 252.381989,270.191275 C217.054092,279.346013 191.117498,295.736455 156.350361,289.695573 C121.645531,283.654691 85.632261,265.967985 64.4479843,236.261792 C43.2637076,206.555598 36.9084246,164.954473 44.7590683,126.778589 C52.609712,88.602706 74.7285892,53.8520651 104.760417,32.926948 C134.854551,11.9395537 172.46594,8.07015224 208.29229,10.748069 C244.056334,13.4259858 277.973032,22.7757751 304.141845,44.3859407 Z"
          id="Path"
          fill="#F5FBFE"
          fillRule="nonzero"
        ></path>
        <g id="Group-3" transform="translate(183.000000, 86.781391)">
          <path
            d="M7,20 L170,20 C173.865993,20 177,23.1340068 177,27 L177,126 C177,129.865993 173.865993,133 170,133 L7,133 C3.13400675,133 4.73447626e-16,129.865993 0,126 L0,27 C4.14730794e-16,23.1340068 3.13400675,20 7,20 Z"
            id="Rectangle"
            fill="#FFFFFF"
          ></path>
          <path
            d="M7,20 L170,20 C173.865993,20 177,23.1340068 177,27 L177,127 C177,130.865993 173.865993,134 170,134 L7,134 C3.13400675,134 4.73447626e-16,130.865993 0,127 L0,27 C4.14730794e-16,23.1340068 3.13400675,20 7,20 Z"
            id="Rectangle"
            stroke="#0073BB"
            strokeWidth="2"
          ></path>
          <circle
            id="Oval"
            fill="#61B407"
            cx="174.5"
            cy="21.7186085"
            r="6.5"
          ></circle>
          <g
            id="Group-18"
            transform="translate(184.000000, 12.000000) scale(-1, 1) translate(-184.000000, -12.000000) translate(171.000000, 0.000000)"
            fill="#0073BB"
          >
            <rect
              id="Rectangle"
              transform="translate(6.000000, 22.000000) rotate(-88.000000) translate(-6.000000, -22.000000) "
              x="5"
              y="17"
              width="2"
              height="10"
              rx="1"
            ></rect>
            <rect
              id="Rectangle"
              transform="translate(12.000000, 11.000000) rotate(-45.000000) translate(-12.000000, -11.000000) "
              x="11"
              y="6"
              width="2"
              height="10"
              rx="1"
            ></rect>
            <rect
              id="Rectangle"
              transform="translate(24.000000, 6.000000) rotate(5.000000) translate(-24.000000, -6.000000) "
              x="23"
              y="1"
              width="2"
              height="10"
              rx="1"
            ></rect>
          </g>
          <g
            id="Group"
            transform="translate(65.000000, 77.000000)"
            fill="#0073BB"
          >
            <circle id="Oval" cx="11" cy="11" r="11"></circle>
            <polygon id="Rectangle" points="26 0 48 22 26 22"></polygon>
            <polygon
              id="Rectangle"
              transform="translate(37.000000, 11.000000) scale(-1, 1) translate(-37.000000, -11.000000) "
              points="26 0 48 22 26 22"
            ></polygon>
          </g>
          <rect
            id="Rectangle"
            fill="#0073BB"
            x="0"
            y="40"
            width="177"
            height="2"
          ></rect>
        </g>
        <g id="Group-22" transform="translate(119.000000, 169.000000)">
          <line
            x1="0"
            y1="5"
            x2="56"
            y2="5.10419892"
            id="Path-2"
            stroke="#0073BB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="0,6"
          ></line>
          <line
            x1="59"
            y1="5.10419892"
            x2="86"
            y2="5.10419892"
            id="Path-2"
            stroke="#0073BB"
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="4,6"
          ></line>
          <rect
            id="Rectangle"
            fill="#0073BB"
            transform="translate(83.000000, 3.500000) rotate(-50.000000) translate(-83.000000, -3.500000) "
            x="82"
            y="0"
            width="2"
            height="7"
            rx="1"
          ></rect>
          <rect
            id="Rectangle"
            fill="#0073BB"
            transform="translate(83.000000, 6.700000) scale(1, -1) rotate(-50.000000) translate(-83.000000, -6.700000) "
            x="82"
            y="3.2"
            width="2"
            height="7"
            rx="1"
          ></rect>
        </g>
        <g id="Group-17" transform="translate(18.000000, 124.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use id="Mask" fill="#FFFFFF" xlinkHref="#path-1"></use>
          <g
            mask="url(#mask-2)"
            fill="#EAF3FF"
            stroke="#0073BB"
            strokeWidth="2"
          >
            <g transform="translate(9.800000, 22.800000)">
              <ellipse id="Oval" cx="35" cy="63" rx="34" ry="29"></ellipse>
              <circle id="Oval-Copy" cx="35" cy="14" r="13"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
