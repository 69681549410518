import "amazon-connect-streams";

/** A subset of the `amazon-connect-streams` library exposed to agent.js consumers. */
export interface AmazonConnectStreamsApi {
  /**
   * The `connect.agent()` function.
   * See: https://github.com/amazon-connect/amazon-connect-streams/blob/master/Documentation.md#connectagent
   */
  agent: typeof connect.agent;

  /**
   * The `connect.contact()` function.
   * See: https://github.com/amazon-connect/amazon-connect-streams/blob/master/Documentation.md#connectcontact
   */
  contact: typeof connect.contact;

  /**
   * A subset of the `connect.core` functions.
   * See: https://github.com/amazon-connect/amazon-connect-streams/blob/master/Documentation.md#connectcore
   */
  readonly core: Pick<connect.Core, "onViewContact" | "viewContact">;
}

/** The callback that gets executed when the CCP initializes. */
export type OnCcpInitCallback = (api: AmazonConnectStreamsApi) => void;

/** The subset `amazon-connect-streams` API object. */
export const CONNECT_API: AmazonConnectStreamsApi = {
  agent: connect.agent,
  contact: connect.contact,
  core: {
    onViewContact: connect.core.onViewContact.bind(connect.core),
    viewContact: connect.core.viewContact.bind(connect.core),
  },
};
