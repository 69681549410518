import { Credentials } from "aws-sdk/lib/core";

import { getCognitoOidcBaseUrl } from "./components/WindowedCcp/auth";

export type Stage = "dev" | "beta" | "gamma" | "prod";

let singleton: Configuration | null = null;

// @VisibleForTesting
export function clearConfig(): void {
  singleton = null;
}

/** Contains the configuration of the module. */
export class Configuration {
  private constructor(
    /** The AWS credentials. */
    public readonly credentials: Credentials,
    /** The stage. */
    public readonly stage: Stage
  ) {}

  /** Gets the `Configuration` singleton instance. */
  public static get instance(): Configuration {
    if (singleton === null) {
      throw new Error(
        "No configuration found. Please initialize the module by using: init()"
      );
    }

    return singleton;
  }

  /** The IT Support Connect API base URL. */
  public get apiBaseUrl(): URL {
    switch (this.stage) {
      case "dev":
      case "beta":
        return new URL("https://api.beta.connect.it.a2z.com");
      case "gamma":
      case "prod":
        return new URL("https://api.connect.it.a2z.com");
    }
  }

  /** The IT Support Connect resources base URL. */
  public get resourcesBaseUrl(): URL {
    switch (this.stage) {
      case "dev":
      case "beta":
        return new URL("https://beta.connect.it.a2z.com");
      case "gamma":
      case "prod":
        return new URL("https://connect.it.a2z.com");
    }
  }

  /** The key used to store AppState in localStorage */
  public get persistedAppStateKey(): string {
    return "connect.agent.state";
  }

  /**
   * Initializes the `Configuration` singleton instance.
   * @param credentials The AWS credentials
   * @param stage The stage
   */
  public static init(credentials: Credentials, stage: Stage): void {
    if (singleton) {
      throw new Error("Configuration cannot be initialized more than once.");
    }

    singleton = new Configuration(credentials, stage);
  }
}

/**
 * Tells where the ccp of agent client is running
 * on a separate window or inside webapp
 */
export function isWindowedCcp(): boolean {
  return window.location.hostname.includes("dev")
    ? // for dev server
      window.location.port === "8080"
    : // for beta, gamma and prod
      window.location.hostname.includes("connect.it.a2z.com");
}

export function getCcpWindowUrl(): string {
  return window.location.hostname.includes("dev")
    ? // for dev server
      "https://dev-dsk.connect.it.a2z.com:8080"
    : // for beta, gamma and prod
      Configuration.instance.resourcesBaseUrl.toString();
}

/**
 * Returns origin of the base window.
 * TODO https://i.amazon.com/issues/CONC-7534 We need to use another approach for it, if multiple clients (not only Omnia Web App) needs to use this function.
 */
export function getBaseWindowOrigin(): string {
  return window.location.hostname.includes("dev")
    ? // for dev server
      // Change the following to `https://dev-dsk.omnia.it.a2z.com:8000`, if testing for popup CCP window while integrating with Omnia Web App in local environment.
      "https://dev-dsk.connect.it.a2z.com:8000"
    : // for beta, gamma and prod
      getCognitoOidcBaseUrl().toString();
}
