/** The error member of a loading state. */
export interface ErrorMember {
  error: Error;
}

/** The loading status member of a loading state. */
export interface LoadingMember {
  loading: boolean;
}

/** A loading state comprised of the loading status and the loading error. */
export interface LoadingState extends Partial<ErrorMember>, LoadingMember {}

/** Derives a loading state from one of its members. */
export function deriveLoadingState(
  member: ErrorMember | LoadingMember
): LoadingState {
  return "error" in member ? { ...member, loading: false } : member;
}
