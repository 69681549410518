import {
  ImgConnectLaunch,
  ImgConnectOffline,
  ImgConnectPoppedOut,
  ImgOfflineNoQueues,
} from "./Base";
import { ImgBase, ImgName } from "./types";

export interface ImgConfig {
  render: React.FC<ImgBase>;
}

export const IMG_CONFIGS: Record<ImgName, ImgConfig> = {
  ["connect-launch"]: {
    render: ImgConnectLaunch,
  },
  ["connect-offline"]: {
    render: ImgConnectOffline,
  },
  ["connect-popped-out"]: {
    render: ImgConnectPoppedOut,
  },
  ["offline-no-queues"]: {
    render: ImgOfflineNoQueues,
  },
};
