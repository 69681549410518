import React from "react";

import { IconBase } from "../types";

export const IconConnect: React.FC<IconBase> = ({ width, height, color }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke={color}>
          <g transform="translate(4.000000, 5.000000)">
            <path
              d="M10.6514728,1.91278829 C10.9619262,1.76774406 11.3037287,1.68902439 11.6585366,1.68902439 C12.9233781,1.68902439 13.9516942,2.67640706 14.0980035,3.93483851 C15.6203035,4.30699989 16.75,5.68010915 16.75,7.31707317 L16.75,7.6097561 C16.75,9.53264995 15.1911865,11.0914634 13.2681729,11.0914634 L10.6605691,11.0910469 L5.3964915,15.9588136 L5.39606562,11.0911018 L2.73170732,11.0914634 C0.808813462,11.0914634 -0.75,9.53264995 -0.75,7.6097561 L-0.75,7.31707317 C-0.75,5.8831882 0.123074115,4.62873559 1.39655193,4.10046444 C1.42117842,1.41741645 3.60381706,-0.75 6.29268293,-0.75 C8.16870693,-0.75 9.83165179,0.313683009 10.6514728,1.91278829 Z"
              strokeWidth="1.5"
            ></path>
            <g transform="translate(2.829268, 3.902439)">
              <line
                x1="3.46341463"
                y1="1.31707317"
                x2="1.41463415"
                y2="2.7804878"
                strokeLinecap="square"
              ></line>
              <line
                x1="4.34146341"
                y1="1.70731707"
                x2="5.51219512"
                y2="3.75609756"
                strokeLinecap="square"
              ></line>
              <line
                x1="6.48780488"
                y1="3.75609756"
                x2="8.24390244"
                y2="2.48780488"
                strokeLinecap="square"
              ></line>
              <circle
                fill="#FFFFFF"
                cx="0.926829268"
                cy="3.17073171"
                r="1"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="3.95121951"
                cy="0.926829268"
                r="1"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="5.70731707"
                cy="4.24390244"
                r="1"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="8.82926829"
                cy="2.09756098"
                r="1"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
