import "./style.scss";

import { default as Button } from "@amzn/awsui-components-react/polaris/button";
import { default as Select } from "@amzn/awsui-components-react/polaris/select";
import React, { useState } from "react";

import { useInstanceSelector } from "../hooks/instance-selector";
import { IconOmnia } from "../IconOmnia";
import { IconPolaris } from "../IconPolaris";

interface LaunchMenu {
  onLaunchClick: () => Promise<void>;
  onCloseClick: () => void;
}

export const LaunchMenu: React.FC<LaunchMenu> = ({
  onLaunchClick,
  onCloseClick,
}) => {
  // Custom hooks
  const {
    options,
    selectedInstanceOption,
    setSelectedInstanceOption,
    instances,
    setSelectedInstance,
  } = useInstanceSelector();

  // Local State
  const [isLaunched, setIsLaunched] = useState<boolean>(false);

  // Handlers
  const handleLaunchClick = async (): Promise<void> => {
    if (selectedInstanceOption) {
      const instance = instances.find(
        (i) => i.ccpUrl === selectedInstanceOption.id
      );
      setSelectedInstance(instance);
      await onLaunchClick();
      setIsLaunched(true);
    }
  };

  return (
    <div
      className="main-connect"
      style={{ display: isLaunched ? "none" : "flex" }}
    >
      {
        // This container header is a temporary measure, this will be reworked following more disucssion with UX team.
      }
      <div className="main-container-header">
        <div className="main-container-header-left">
          <IconOmnia
            name="connect"
            variant="alternate"
            className="main-container-header-icon"
          />
          <div className="main-container-header-text">{"Amazon Connect"}</div>
        </div>
        <div className="main-container-header-right">
          <IconPolaris name="close" onClick={onCloseClick} variant="normal" />
        </div>
      </div>
      <div className="main-connect-content">
        <div className="main-connect-online-status">
          {
            // Styled placeholder div, should contain the "online status" once implemented
          }
        </div>
        <div className="main-connect-body">
          <div className="main-connect-body-text-primary">
            {"Select instance"}
          </div>
          <div className="main-connect-body-text-secondary">
            {"Please select an instance to launch"}
          </div>
          <Select
            placeholder={"Select Instance"}
            className={"main-connect-instance-select"}
            options={options}
            onChange={(e) => setSelectedInstanceOption(e.detail.selectedOption)}
            selectedOption={selectedInstanceOption}
          />
          {selectedInstanceOption && (
            <Button
              className="main-connect-instance-launch"
              variant="primary"
              onClick={handleLaunchClick}
            >
              {"Launch Connect"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
