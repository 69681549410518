import React, { useContext, useEffect, useRef } from "react";

import { AppStateContext } from "../../state/provider";
import { CfmWidgetConfig } from "./config";

const CUSTOM_COMPONENT_NAME = "git-feedback-inline-widget";
const CFM_WIDGET_URL =
  "https://feedback-widgets.it.a2z.com/git-feedback-widgets-1.2.bundled.js";

/**
 * The available functions from the GIT CFM widget.
 * @see https://tiny.amazon.com/i9ppxa9y/codeamazpackGITCblobfe45src
 */
export interface HTMLElementCfmWidget extends HTMLElement {
  initialize: (config: CfmWidgetConfig) => void;
  start: () => void;
  destroy: () => void;
  stop: () => void;
}

export interface CfmWidgetProps {
  contactID?: string;
  configFunc: (agentUsername: string, contactID: string) => CfmWidgetConfig;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      [CUSTOM_COMPONENT_NAME]: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

/**
 * This is a React wrapper for the CFM widget. It automatically detects if the CFM widget is already defined and loads the script to define it if not.
 * @returns the React component for CFM widget
 */
export const CfmWidget: React.FC<CfmWidgetProps> = (props) => {
  const ref = useRef<HTMLElement>(null);
  const appState = useContext(AppStateContext);

  /**
   * Grab script to define git-feedback-inline-widget if it is not defined.
   */
  useEffect(() => {
    if (!customElements.get(CUSTOM_COMPONENT_NAME)) {
      const script = document.createElement("script");
      script.async = true;
      script.src = CFM_WIDGET_URL;
      script.type = "text/javascript";
      document.body.appendChild(script);
    }
  }, [appState.ccpInitialized]);

  /**
   * Start the CFM widget when git-feedback-inline-widget is defined.
   */
  useEffect(() => {
    let cfmWidgetConfig: CfmWidgetConfig;
    if (appState.ccpInitialized) {
      connect.agent((agent) => {
        const agentUsername = agent.getConfiguration().username;
        if (agentUsername && props.contactID) {
          cfmWidgetConfig = props.configFunc(agentUsername, props.contactID);
        }
        if (cfmWidgetConfig) {
          void customElements
            .whenDefined(CUSTOM_COMPONENT_NAME)
            .then(() => {
              if (ref.current) {
                const cfmWidget = ref.current as HTMLElementCfmWidget;
                cfmWidget.initialize(cfmWidgetConfig);
                cfmWidget.start();
              }
              return;
            })
            .catch((err) => console.error(err));
        } else {
          console.error("CFM Widget config is null/undefined.");
        }
      });
    }
  }, [appState.ccpInitialized]);

  return (
    <div>
      <git-feedback-inline-widget ref={ref} />
    </div>
  );
};
